export const DISABLED_VARIANT = 'control'
const DEFAULT_EXPERIMENT_VARIANTS = [DISABLED_VARIANT, 'treatment']

type ExperimentConfigType = {
  variants: {
    name: string
    distribution?: number // Between 0 and 1. Default is equal distribution
  }[]
}

type ExperimentsConfigType = {
  [experimentName: string]: ExperimentConfigType
}

const getTotalAllocatedDistribution = (config: ExperimentConfigType) => {
  return config.variants
    .map((v) => v.distribution)
    .reduce((total, d) => total + (d || 0), 0)
}

const createExperimentConfig = (config: ExperimentConfigType) => {
  const numOfVariantsForEvenDistribution = config.variants.filter(
    (v) => v.distribution == null
  ).length
  const defaultDistribution =
    (1 - getTotalAllocatedDistribution(config)) /
    numOfVariantsForEvenDistribution
  config.variants.forEach((v) => {
    v.distribution = v.distribution || defaultDistribution
  })

  const totalAllocatedDistribution =
    +getTotalAllocatedDistribution(config).toFixed(2)
  if (totalAllocatedDistribution !== 1) {
    throw new Error(
      `Experiment distribution should accumulate to 1, but instead was ${totalAllocatedDistribution}: ${JSON.stringify(
        config
      )}`
    )
  }
  return config
}

export enum EXPERIMENT_NAMES {
  EPTEST_TEST_EXPERIMENT = 'eptest_test_experiment',
}

export const EXPERIMENTS_CONFIG: ExperimentsConfigType = {
  [EXPERIMENT_NAMES.EPTEST_TEST_EXPERIMENT]: createExperimentConfig({
    variants: DEFAULT_EXPERIMENT_VARIANTS.map((v) => ({
      name: v,
    })),
  }),
}
