import config from '../config/config'

export const BASE_URL = `${config.easyplantBackendUrl}/api`

export const apiRequest = async (method, endpoint, data = {}, raw = false) => {
  const queryString = new URLSearchParams(data).toString()

  const response = await fetch(
    `${BASE_URL}${endpoint}${method === 'GET' ? `?${queryString}` : ''}`,
    {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: method !== 'GET' ? JSON.stringify(data) : null,
    }
  )

  return raw ? response.text() : response.json()
}

export const get = async (endpoint, data = {}, raw = false) => {
  return apiRequest('GET', endpoint, data, raw)
}

export const post = async (endpoint, data = {}, raw = false) => {
  return apiRequest('POST', endpoint, data, raw)
}
