import useMediaQuery from '@mui/material/useMediaQuery'
import {
  LAYOUT_DESKTOP_ORIGIN_WIDTH,
  LAYOUT_DESKTOP_THRESHOLD,
} from '@utils/styleUtils'

export const BREAKPOINTS = {
  xs: 0,
  sm: 320,
  md: 640,
  lg: LAYOUT_DESKTOP_THRESHOLD,
  xl: LAYOUT_DESKTOP_ORIGIN_WIDTH,
}

export const getMediaQueryHelpers = (theme) => {
  const getMediaQuery = (size) => theme.breakpoints.up(size)

  const mediaQueryHelper = (size) => (style) => ({
    [getMediaQuery(size)]: style,
  })

  const getMediaQueryHook =
    (size) =>
    (options = {}) => {
      return useMediaQuery(getMediaQuery(size).split(' ')[1], options)
    }

  return {
    onSmallScreen: mediaQueryHelper('sm'),
    onMediumScreen: mediaQueryHelper('md'),
    onLargeScreen: mediaQueryHelper('lg'),

    useSmallScreen: getMediaQueryHook('sm'),
    useMediumScreen: getMediaQueryHook('md'),
    useLargeScreen: getMediaQueryHook('lg'),
  }
}

export default BREAKPOINTS
