import { urlParamContains } from '@utils/urlUtils'
import {
  getUserBrowser,
  isBotUserAgent,
  isSmallScreen,
} from '@utils/browserUtils'
import { isMobile } from 'react-device-detect'
import userService from '../userService'
import Helpers from '@lib/utils/helpers.js'

export const getFullUserAttributes = () => {
  const viaCampaignOrEmail =
    Helpers.getURLParam('utm_source') === 'email' ||
    urlParamContains('utm_campaign', ['retargeting', 'repeat', 'rmkt']) ||
    Helpers.getURLParam('sms') === '1'

  return {
    ...userService.getUserAttributes(),
    via_campaign_or_email: viaCampaignOrEmail,
    is_mobile: isMobile,
    is_small_screen: isSmallScreen(),
    browser: getUserBrowser(),
    is_bot_user_agent: isBotUserAgent(),
  }
}
