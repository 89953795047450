import { SessionStorageKeys } from './keys'
import { isBrowser, isServer } from '@utils/runtimeUtils'

function set(key, value) {
  if (isBrowser()) {
    try {
      window.sessionStorage.setItem(key, value)
    } catch {
      console.warn(' Error reading from local storage')
    }
  }
}

function get(key) {
  if (isServer()) {
    return null
  }

  try {
    return window.sessionStorage.getItem(key)
  } catch {
    return null
  }
}

const isUserAuthenticated = () =>
  get(SessionStorageKeys.IS_AUTHENTICATED) === 'true'
const setUserAsAuthenticated = () =>
  set(SessionStorageKeys.IS_AUTHENTICATED, 'true')

const getAmplitudeSessionId = () => get(SessionStorageKeys.AMPLITUDE_SESSION_ID)
const setAmplitudeSessionId = (sessionId) =>
  set(SessionStorageKeys.AMPLITUDE_SESSION_ID, sessionId)

const getUTMParams = () => {
  const utmParams = get(SessionStorageKeys.UTM_PARAMS_KEY)
  if (!utmParams) {
    return null
  }

  return JSON.parse(utmParams)
}

const setUTMParams = (utmParams) =>
  set(SessionStorageKeys.UTM_PARAMS_KEY, JSON.stringify(utmParams))

const getIRCClickId = () => get(SessionStorageKeys.IRC_CLICK_ID)

const getLastSeenCategory = () => get(SessionStorageKeys.LAST_SEEN_CATEGORY)
const setLastSeenCategory = (value) =>
  set(SessionStorageKeys.LAST_SEEN_CATEGORY, value)

const getLastVisitedPlantHandle = () =>
  get(SessionStorageKeys.LAST_VISITED_PLANT_HANDLE)
const setLastVisitedPlantHandle = (value) =>
  set(SessionStorageKeys.LAST_VISITED_PLANT_HANDLE, value)

const getGiftingCollectionShownAmount = () => {
  const collectionsAmountJSON = get(
    SessionStorageKeys.GIFTING_COLLECTIONS_SHOWN_AMOUNT
  )
  return collectionsAmountJSON ? JSON.parse(collectionsAmountJSON) : {}
}

const setGiftingCollectionShownAmount = (collectionHandle, amount) => {
  const collectionsAmount = getGiftingCollectionShownAmount()

  collectionsAmount[collectionHandle] = amount
  set(
    SessionStorageKeys.GIFTING_COLLECTIONS_SHOWN_AMOUNT,
    JSON.stringify(collectionsAmount)
  )
}

export const SessionStorage = {
  get,
  set,
  isUserAuthenticated,
  setUserAsAuthenticated,
  getAmplitudeSessionId,
  setAmplitudeSessionId,
  getUTMParams,
  setUTMParams,
  getIRCClickId,
  getLastSeenCategory,
  setLastSeenCategory,
  getLastVisitedPlantHandle,
  setLastVisitedPlantHandle,
  getGiftingCollectionShownAmount,
  setGiftingCollectionShownAmount,
}
