import * as amplitude from '@amplitude/analytics-browser'
import { getFullUserAttributes } from '../Features/utils'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import Config from '@config/config'
import EVENT_NAMES from 'consts/eventNames'

const { isProduction } = Config

type AmplitudeProperties = { [property: string]: string | number | boolean }

export default class AmplitudeService {
  private readonly sdkKey: string

  constructor({ sdkKey }: { sdkKey: string }) {
    this.sdkKey = sdkKey
  }

  init({ userId }: { userId: string }) {
    const options = {
      includeReferrer: true,
      includeUtm: true,
      saveParamsReferrerOncePerSession: false,
      includeGclid: true,
      defaultTracking: {
        sessions: true,
        attribution: false,
      },
    }

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: isProduction ? 0.1 : 0,
    })
    amplitude.add(sessionReplayTracking)

    amplitude.init(this.sdkKey, userId, options)

    this.setUserProperties(getFullUserAttributes())
  }

  setUserProperties(userProperties: AmplitudeProperties) {
    const identify = new amplitude.Identify()
    Object.entries(userProperties).forEach(([key, value]) => {
      identify.set(key, value)
    })
    amplitude.identify(identify)
  }

  identifyUser(userId: string) {
    amplitude.setUserId(userId)
  }

  setEmail(email: string) {
    this.setUserProperties({ Email: email })
  }

  setUserName(name: string) {
    this.setUserProperties({ Name: name })
  }

  track(eventName: string, eventProperties: AmplitudeProperties) {
    if (amplitude.getSessionId()) {
      amplitude.logEvent(eventName, eventProperties)
    } else {
      setTimeout(() => {
        // first screen loaded (give it some time to load the amplitude package)
        amplitude.logEvent(eventName, eventProperties)
      }, 1000)
    }
  }

  async trackCheckoutClicked(eventProperties: AmplitudeProperties) {
    amplitude.setTransport('beacon')
    amplitude.track(EVENT_NAMES.CHECKOUT_CLICKED_SYNC, eventProperties)
    amplitude.flush()
  }

  getSessionId(): number {
    return amplitude.getSessionId()
  }

  setSessionId(sessionId: number) {
    return amplitude.setSessionId(sessionId)
  }
}
