import Helpers from '../helpers'
import Cookies from 'js-cookie'
import { GIFTING_ACCESS_COOKIE_KEY, GIFTING_ACCESS_KEY } from './consts'

export const isGiftingAccessViewer = () => {
  const giftingAccessParam = Helpers.getURLParam(GIFTING_ACCESS_KEY)
  const isGiftingAccess = giftingAccessParam === 'true' || false

  if (!isGiftingAccess) {
    return Cookies.get(GIFTING_ACCESS_COOKIE_KEY) === 'true'
  }

  Cookies.set(GIFTING_ACCESS_COOKIE_KEY, 'true', { expires: 30 })
  return true
}
