import { getFullUserAttributes } from 'services/Features/utils'
import { post } from 'services/backendService'
import { v4 as uuid } from 'uuid'
import userService from 'services/userService'

export const SNAPCHAT_TRACK_EVENTS = {
  PAGE_VIEW: 'PAGE_VIEW',
  VIEW_CONTENT: 'VIEW_CONTENT',
  ADD_TO_CART: 'ADD_CART',
  SIGN_UP: 'SIGN_UP',
}

const snapEventValues = Object.values(SNAPCHAT_TRACK_EVENTS)

export default class SnapchatService {
  track(eventName, eventProperties) {
    // no track event for snapchat, should send only standard events
  }

  trackSnapchatEvent(eventName, eventProperties = {}) {
    if (!window.snaptr || !snapEventValues.includes(eventName)) {
      return
    }

    window.snaptr('track', eventName, {
      ...eventProperties,
      ...getFullUserAttributes(),
      referrer: document.referrer,
    })

    const eventId = uuid()
    const email = userService.getUserEmail()
    const userId = userService.getUserId()

    post('/snapchat/track', {
      eventName,
      eventTime: Date.now(), // UTC+0 Unix timestamp in miliseconds
      eventId,
      email,
      userId,
      properties: eventProperties,
    }).catch((err) => {
      console.error(
        `Snapchat S2S event failed: ${err.message}, event: ${eventName}`,
        err
      )
    })
  }

  trackSnapchatOfflineEvent(eventName, eventProperties = {}, { email }) {
    const eventId = uuid()
    const userId = userService.getUserId()

    post('/snapchat/track-offline', {
      eventName,
      eventTime: Date.now(), // UTC+0 Unix timestamp in miliseconds
      eventId,
      email,
      userId,
      properties: eventProperties,
    }).catch((err) => {
      console.error(
        `Snapchat S2S event failed: ${err.message}, event: ${eventName}`,
        err
      )
    })
  }

  trackAddToCart({ id, name, collection, value }) {
    this.trackSnapchatEvent(
      SNAPCHAT_TRACK_EVENTS.ADD_TO_CART,
      this.generateSnapParams({ id, collection, value })
    )
  }

  trackViewContent({ id, name, collection, value }) {
    this.trackSnapchatEvent(
      SNAPCHAT_TRACK_EVENTS.VIEW_CONTENT,
      this.generateSnapParams({ id, collection, value })
    )
  }

  generateSnapParams({ id, collection, value }) {
    return {
      price: value,
      currency: 'USD',
      item_ids: [id],
      item_category: collection,
      number_items: 1,
    }
  }
}
