import { useEffect } from 'react'
import NavigationService from 'services/navigationService'

const useIntercom = ({ appId }) => {
  useEffect(() => {
    if (!appId) {
      return
    }
    // Intercom code snippet
    ;(function () {
      const w = window
      // @ts-ignore
      const ic = w.Intercom
      if (typeof ic === 'function') {
        ic('reattach_activator')
        // @ts-ignore
        ic('update', w.intercomSettings)
      } else {
        const d = document
        var i = function () {
          i.c(arguments)
        }
        i.q = []
        i.c = function (args) {
          i.q.push(args)
        }
        // @ts-ignore
        w.Intercom = i
        const l = function () {
          const s = d.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = `https://widget.intercom.io/widget/${appId}`
          const x = d.getElementsByTagName('script')[0]
          x.parentNode.insertBefore(s, x)
        }
        if (document.readyState === 'complete') {
          l()
          // @ts-ignore
        } else if (w.attachEvent) {
          // @ts-ignore
          w.attachEvent('onload', l)
        } else {
          w.addEventListener('load', l, false)
        }
      }
    })()

    // Call boot method
    window.Intercom('boot', {
      app_id: appId,
      hide_default_launcher: true, // Don't show the intercom button
    })
  }, [])

  useEffect(() => {
    if (!appId) {
      return
    }
    const handleRouteChange = () => {
      window.Intercom('update')
    }

    return NavigationService.subscribeToPageChanges(handleRouteChange)
  }, [])
}

export default useIntercom
