import Drawer from '@mui/material/Drawer'
import { useLargeScreen } from '@config/Theme/theme'
import { styled } from '@mui/material/styles'

export const ANCHOR_TYPES = {
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
}

export const CART_SLIDE_ANIMATION_TIME = 500

const StyledEasyplantDrawer = styled(Drawer)<{ $margin?: number }>`
  z-index: 99999;
  .MuiDrawer-paper {
    background-color: ${({ theme }) => theme.colors.cream3};
    margin-inline: ${({ $margin }) => $margin || 0}px;

    overflow-y: auto;
    overflow-x: hidden;
  }

  .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    min-height: 370px;
    max-height: calc(100% - 32px);
  }

  .MuiDrawer-paperAnchorRight {
    width: 400px;
  }
`

const EasyplantDrawer = (drawerProps) => {
  const isDesktop = useLargeScreen()
  const anchor = isDesktop ? ANCHOR_TYPES.RIGHT : ANCHOR_TYPES.BOTTOM

  const sliderProps = {
    ...(drawerProps?.SliderProps || {}),
    timeout: CART_SLIDE_ANIMATION_TIME,
  }

  return (
    <StyledEasyplantDrawer
      {...drawerProps}
      anchor={anchor}
      SlideProps={sliderProps}
      $margin={drawerProps?.margin}
    />
  )
}

export default EasyplantDrawer
