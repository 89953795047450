import { POT_TYPES } from '../consts/pots'

const POT_CODE_TO_POT_TYPE = {
  SA: POT_TYPES.AMBER,
  MA: POT_TYPES.AMBER,
  MM: POT_TYPES.MONET,
  ME: POT_TYPES.ERA,
  LT: POT_TYPES.TUSCA,
  LA: POT_TYPES.AGATHA,
  SY: POT_TYPES.YOKO,
}

export const getPotTypeBySKU = (sku) => {
  const skuParts = sku.split('-')
  if (skuParts.length < 5) {
    return 'N/A'
  }

  // eslint-disable-next-line no-unused-vars
  const [_, __, ___, potTypeCode, ____] = skuParts

  if (!POT_CODE_TO_POT_TYPE[potTypeCode]) {
    return 'N/A'
  }

  return POT_CODE_TO_POT_TYPE[potTypeCode]
}
