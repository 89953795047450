import { useEffect, useLayoutEffect } from 'react'
import { PAGE_ROUTES, PAGES } from '../consts/pages'

const collectionPagePaths = [
  PAGE_ROUTES[PAGES.COLLECTIONS],
  PAGE_ROUTES[PAGES.SMALL],
  PAGE_ROUTES[PAGES.MEDIUM],
  PAGE_ROUTES[PAGES.LARGE],
  PAGE_ROUTES[PAGES.HUGE],
]

export const isServer = () => typeof window === 'undefined'
export const isBrowser = () => !isServer()
export const useBrowserLayoutEffect = isServer() ? useEffect : useLayoutEffect
export const isCollectionsPage = (router) => {
  let currentPath = router?.asPath.split('?')[0] || ''
  return router && collectionPagePaths.includes(currentPath.substring(1)) // currentPath.substring(1) removes the leading slash
}
export const isGiftingPage = (router) => {
  const GIFTING_PATH = 'gifting'
  let currentPath = router?.asPath.split('?')[0] || ''
  return router && currentPath.includes(GIFTING_PATH)
}
