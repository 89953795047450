import { Builder, builder } from '@builder.io/react'
import { Component } from '@builder.io/sdk'
import dynamic from '@utils/dynamic'
import env from './config'
import { carouselConfig } from '@components/Carousel/Carousel.config'

if (!env.builderPublicKey) {
  throw new Error('Missing env variable NEXT_PUBLIC_BUILDER_PUBLIC_KEY')
}

const builderConfig = {
  apiKey: env.builderPublicKey,
  productsModel: 'shopify-[collectionHandle]',
  collectionsModel: 'shopify-collection',
}

builder.init(builderConfig.apiKey)
builder.apiVersion = 'v3'

function restrictedRegister(
  component: any,
  options: Component,
  models: string[]
) {
  if (!Builder.isEditing || models.includes(builder.editingModel!)) {
    return Builder.registerComponent(component, options)
  }
}

/***********************/
/* Register components */
/***********************/

Builder.registerComponent(
  dynamic(
    () => import('@components/BuilderIO/CloudinaryImage/CloudinaryImage')
  ),
  {
    name: 'CloudinaryImage',
    image:
      'https://res.cloudinary.com/cloudinary-marketing/image/upload/v1599098500/creative_source/Logo/Cloud%20Glyph/cloudinary_cloud_glyph_blue_png.png',
    inputs: [{ name: 'cloudinaryOptions', type: 'cloudinaryImageEditor' }],
  }
)

Builder.registerComponent(
  dynamic(() => import('@components/ui/Button/Button')),
  {
    name: 'Core:Button',
    // @ts-ignore
    override: true,
    inputs: [
      {
        name: 'text',
        type: 'string',
        required: false,
        defaultValue: 'Click me',
      },
      {
        name: 'link',
        type: 'url',
        required: false,
      },
      {
        name: 'color',
        type: 'string',
        required: false,
        enum: ['default', 'primary', 'secondary'],
        defaultValue: 'default',
      },
      {
        name: 'variant',
        type: 'string',
        required: false,
        enum: ['default', 'normal', 'media'],
        defaultValue: 'default',
      },
    ],
    noWrap: true,
  }
)

Builder.registerComponent(
  dynamic(() => import('react-modal-video')),
  {
    name: 'VideoModal',
    friendlyName: 'Video Modal',
    inputs: [
      {
        name: 'videoId',
        type: 'string',
        required: true,
        friendlyName: 'Video ID',
      },
      {
        name: 'channel',
        type: 'enum',
        enum: ['vimeo'],
        required: true,
        defaultValue: 'vimeo',
      },
      {
        name: 'isOpen',
        type: 'boolean',
        required: true,
        defaultValue: false,
      },
      {
        name: 'onClose',
        type: 'code',
        advanced: true,
      },
    ],
    noWrap: true,
  }
)

Builder.registerComponent(
  dynamic(async () => (await import('@components/FAQList/FAQListV2')).FAQList),
  {
    name: 'FAQList',
    inputs: [
      {
        name: 'origin',
        type: 'string',
      },
      {
        name: 'faqData',
        type: 'list',
        subFields: [
          { name: 'title', type: 'string', required: true },
          { name: 'text', type: 'richText', required: true },
        ],
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(async () => (await import('@components/FAQList/FAQListV2')).FAQList),
  {
    name: 'FAQListV2',
    inputs: [
      {
        name: 'faqData',
        type: 'list',
        subFields: [
          { name: 'title', type: 'string', required: true },
          { name: 'text', type: 'richText', required: true },
        ],
      },
      {
        name: 'origin',
        type: 'string',
        defaultValue: 'homepage',
        helperText: 'Name of the page',
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(
    async () =>
      (await import('@components/BuilderIO/InteractiveImage')).InteractiveImage
  ),
  {
    name: 'InteractiveImage',
    inputs: [
      {
        name: 'image',
        type: 'file',
        allowedFileTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg'],
        required: true,
      },
      {
        name: 'editingWidth',
        type: 'number',
        showIf: `false`,
      },
      {
        name: 'caption',
        type: 'text',
      },
      {
        name: 'products',
        type: 'list',
        helperText:
          'IMPORTANT! Please move interactive elements only in desktop mode. Do not resize browser window while moving elements. Try to keep some distance from edges so scaling works properly.',

        // this is called "showIf", however
        // it is actually a one-time initializer that
        // should always return true
        showIf: (opts: { name?: string; [key: string]: any }) => {
          try {
            if (globalThis.top.__interactiveImageInitialized) return true

            globalThis.top.addEventListener('message', (e) => {
              try {
                const data = JSON.parse(e.data)

                if (!data.__interactiveImage) return

                if (data.xPos && data.yPos) {
                  Object.assign(globalThis.top.__interactiveImageData, {
                    xPos: data.xPos,
                    yPos: data.yPos,
                  })
                }

                if (data.imageWidth) {
                  opts.set('editingWidth', data.imageWidth)

                  Object.assign(globalThis.top.__interactiveImageData, {
                    originWidth: data.imageWidth,
                  })
                }
              } catch (e) {}
            })

            globalThis.top.__interactiveImageData = {}
            globalThis.top.__interactiveImageData[opts.name] =
              opts.get('products')?.length || 0
            globalThis.top.__interactiveImageInitialized = true
          } catch (e) {}

          return true
        },

        // this is used to move a new product to the default position
        // because builder copies the last entry (with coordinates) and the new dot appears on top of the previous one
        onChange: (opts) => {
          try {
            const globalData = globalThis.top.__interactiveImageData || {}
            const products = opts.get('products')

            if (products.length > globalData[opts.name]) {
              const lastProduct = products[products.length - 1]

              lastProduct.set('xPos', 100)
              lastProduct.set('yPos', 100)
              lastProduct.set('movementMove', false)
            }

            globalData[opts.name] = products.length
          } catch (e) {}
        },

        subFields: [
          { name: 'product', type: 'reference', model: 'product-seo' },
          {
            name: 'variantName',
            helperText: 'Variant (color) to be displayed in the popup',
            type: 'text',
          },
          {
            name: 'handleOverride',
            helperText:
              'You may want to specify a product handle here if it has multiple handles',
            type: 'text',
          },
          { name: 'xPos', type: 'number', showIf: `false`, defaultValue: 100 },
          { name: 'yPos', type: 'number', showIf: `false`, defaultValue: 100 },
          {
            name: 'originWidth',
            type: 'number',
            showIf: `false`,
            defaultValue: 0,
          },
          {
            name: 'movementMode',
            type: 'boolean',
            defaultValue: false,
            helperText:
              "In the movement mode you can drag&drop interactive point on the image. Please always switch it back to disabled after positioning, otherwise it won't be saved.",

            // cannot pass native function to the onChange in sublists
            // this is a hack
            onChange: `
              (() => {
                const globalData = globalThis.top.__interactiveImageData || {}
                const mode = options.get('movementMode')

                if (mode && globalData.isMoving) {
                  alert('You already have one product in the movable state! Please save it first by disabling movement switch.')
                  options.set('movementMode', false)
                  return
                } else if (mode) {
                  globalData.isMoving = true
                } else if (!mode) {
                  if (globalData.xPos && globalData.yPos) {
                    options.set('xPos', globalData.xPos)
                    options.set('yPos', globalData.yPos)
                  }

                  if (globalData.originWidth) {
                     options.set('originWidth', globalData.originWidth)
                  }

                  globalData.isMoving = false
                }
              })()
            `,
          },
        ],
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(
    async () =>
      (await import('@components/Carousel/Carousel')).CarouselComponent
  ),
  carouselConfig
)

Builder.registerComponent(
  dynamic(
    async () => await import('@components/CollectionsGrid/CollectionsGrid')
  ),
  {
    name: 'CollectionsGrid',
    inputs: [
      {
        name: 'lazy',
        type: 'boolean',
        defaultValue: true,
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(async () => await import('@components/ui/Button/TransparentButton')),
  {
    name: 'TransparentButton',
    inputs: [
      {
        name: 'text',
        type: 'string',
        required: true,
        defaultValue: 'Click me',
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(
    async () =>
      await import(
        '@components/BuilderIO/BlogRelatedArticles/BlogRelatedArticles'
      )
  ),
  {
    name: 'BlogRelatedArticles',
    models: ['blog-article'],
    inputs: [
      {
        name: 'articles',
        type: 'list',
        subFields: [
          { name: 'article', type: 'reference', model: 'blog-article' },
        ],
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(
    async () =>
      (await import('@components/HeroVideo/HeroVideo')).MobileHeroVideo
  ),
  {
    name: 'MobileHeroVideo',
  }
)

/******************/
/* Register menus */
/******************/

Builder.register('insertMenu', {
  name: 'easyplant Components',
  items: [
    { name: 'FAQList', label: 'FAQ List' },
    { name: 'FAQListV2', label: 'FAQ List V2' },
    { name: 'Carousel', label: 'Carousel' },
    { name: 'Core:Button', label: 'Button' },
    { name: 'TransparentButton', label: 'Transparent Button' },
    { name: 'VideoModal', label: 'Video Modal' },
    { name: 'InteractiveImage', label: 'Interactive Image' },
    { name: 'CollectionsGrid', label: 'Collections Grid' },
    { name: 'BlogRelatedArticles', label: 'Blog related articles' },
    { name: 'MobileHeroVideo', label: 'Mobile Hero Video' },
  ],
})

Builder.register('insertMenu', {
  name: 'Cloudinary Components',
  items: [{ name: 'CloudinaryImage' }],
})

export default builderConfig
