import { useContext } from 'react'
import { Context } from '../Context'
import { isVariantSKUASet } from '../../../../../services/setsService'
import { isBundleStandAttribute } from 'services/customAttributes'
import { isVariantSKUAStand } from 'services/standService'

export function useCartCount(
  { withSets, withStands } = { withSets: true, withStands: true }
) {
  const { cart } = useContext(Context)
  if (cart == null || cart.lineItems.length < 1) {
    return 0
  }

  const count = cart.lineItems
    .filter(
      (item) => item.variant && !isBundleStandAttribute(item.customAttributes)
    )
    .filter((item) => withStands || !isVariantSKUAStand(item.variant.sku))
    .reduce((totalLineItemsQuantity, lineItemObject) => {
      if (!withSets && isVariantSKUASet(lineItemObject.variant.sku)) {
        return totalLineItemsQuantity
      }

      return totalLineItemsQuantity + lineItemObject.quantity
    }, 0)

  return count
}
