import Script from 'next/script'

const Klaviyo = ({ companyId }) => (
  <Script
    src={`//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${companyId}`}
    id='klaviyo'
    strategy='afterInteractive'
  />
)

export default Klaviyo
