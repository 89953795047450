import { v4 as uuidv4 } from 'uuid'
import { getUTMParamsFromUrl } from './utmParamsService'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'

const createUserID = () => `EP_${uuidv4()}`

const {
  getUserId,
  setUserId,
  getIsNewUser: isNewUser,
  setIsNewUser,
  getUserEmail,
  getUserName,
} = LocalStorage

const getOrCreateUserId = () => {
  let userId = getUserId()
  if (!userId) {
    userId = createUserID()
    setUserId(userId)
    setIsNewUser('true')
  } else {
    setIsNewUser('false')
  }

  return userId
}

const getUserAttributes = () => ({
  ...getUTMParamsFromUrl(),
  isNewUser: isNewUser(),
})

export default {
  getUserId,
  getOrCreateUserId,
  getUserAttributes,
  isNewUser,
  getUserEmail,
  getUserName,
}
