import { isServer } from '@utils/runtimeUtils'
import { SessionStorage } from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'
import userService from './userService'
import analyticsManager from './analytics/analyticsManager'
import Helpers from '@lib/utils/helpers'
import { staticExperimentManager } from './experiments/experimentsManager'

/*
This functions runs on the first time Client Side is loaded and should only initialize analytics once per session
 */
export function initOnAppLoad() {
  if (isServer()) {
    return
  }

  const userId = userService.getOrCreateUserId()

  analyticsManager.init({ userId })
  SessionStorage.setUserAsAuthenticated()
  staticExperimentManager.init()
  intercomOnLoad()

  return userId
}

const intercomOnLoad = () => {
  const shouldOpenIntercom = !!Helpers.getURLParam('openIntercom')

  // We set a timeout of 500 to make sure the intercom script is loaded and we don't have a race condition
  if (shouldOpenIntercom) {
    setTimeout(() => {
      Helpers.openIntercomChat('URL')
    }, 200)
  }
}
