import Helpers from '@lib/utils/helpers'
import { getPotTypeBySKU } from './potService'
import { isVariantSKUASet } from './setsService'

export const getProductCustomAttributes = (
  sku: string,
  collection: string | string[],
  tags: string[]
) => {
  const isSet = isVariantSKUASet(sku)

  const customAttributes = isSet
    ? []
    : [
        { key: 'Size', value: collection },
        { key: 'Pot', value: getPotTypeBySKU(sku) },
        { key: 'BN', value: Helpers.getScientificNameTag(tags) },
      ]

  return customAttributes
}

export const PLANT_WITH_STAND_ATTRIBUTE_KEY = 'Accessory'
export const STAND_BUNDLED_ATTRIBUTE_KEY = 'bundled'

export const getPlantAccessoryAttributeValue = (
  customAttributes: ShopifyBuy.Attribute[]
): string => {
  const accessoryAttr = customAttributes.filter(
    (attr) => attr.key === PLANT_WITH_STAND_ATTRIBUTE_KEY
  )

  return accessoryAttr.length > 0 ? accessoryAttr[0].value : null
}

export const isBundleStandAttribute = (
  customAttributes: ShopifyBuy.Attribute[]
) => {
  const standBundledAttr = customAttributes.filter(
    (attr) => attr.key === STAND_BUNDLED_ATTRIBUTE_KEY
  )

  return standBundledAttr.length > 0
}
