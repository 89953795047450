import { useLargeScreen } from '@config/Theme/theme'

export const RenderOnMobile = ({ children }) => {
  const isDesktop = useLargeScreen()

  return (
    <RenderOnCondition condition={!isDesktop}>{children}</RenderOnCondition>
  )
}

export const RenderOnDesktop = ({ children }) => {
  const isDesktop = useLargeScreen()
  return <RenderOnCondition condition={isDesktop}>{children}</RenderOnCondition>
}

const RenderOnCondition = ({ condition = false, children }) => {
  if (!condition) {
    return null
  }

  return children
}
