const CART = 'epShopifyCart'
const CHECKOUT_ID = 'epShopifyCheckoutId'
const USER_ID = 'epUserId'
const ONBOARDING_DONE = 'onboarding_done'
const EMAIL_CAPTURE_POPUP_OPENED = 'email_capture_popup_opened'
const IS_NEW_USER = 'isNewUser'
const USER_NAME = 'epUserName'
const USER_EMAIL = 'epEmail'
const SHOULD_AUTO_LOAD_FULLSTORY = 'EP_shouldAutoLoadFullstory'
const FORCE_COUNTRY = 'forceCountry'
const FORCE_REGION = 'forceRegion'
const RECENTLY_VIEWED_PRODUCTS = 'recentlyViewedProducts'

export const LocalStorageKeys = {
  CART,
  CHECKOUT_ID,
  USER_ID,
  IS_NEW_USER,
  USER_NAME,
  USER_EMAIL,
  ONBOARDING_DONE,
  EMAIL_CAPTURE_POPUP_OPENED,
  SHOULD_AUTO_LOAD_FULLSTORY,
  FORCE_COUNTRY,
  FORCE_REGION,
  RECENTLY_VIEWED_PRODUCTS,
}
