import Helpers from '../../lib/utils/helpers'
import * as S from './TalkToUsIcon.styles'
import { RenderOnDesktop } from '@config/Theme/sizeDisplays'

export default function TalkToUsIcon() {
  const openIntercomChat = () => {
    Helpers.openIntercomChat('Chat with us Icon')
  }

  return (
    <RenderOnDesktop>
      <S.StyledTalkToUsIcon onClick={openIntercomChat} />
    </RenderOnDesktop>
  )
}
