import theme from '@config/Theme/theme'
import { LimitedEdition, LowLight, PetFriendly, PetFriendly2 } from '../icons'

export const PRODUCT_TRAITS = {
  PET_FRIENDLY: 'Pet Friendly',
  PET_FRIENDLY_V2: 'Pet Safe',
  LOW_LIGHT: 'Low Light',
  LIMITED_EDITION: 'Limited Edition',
  LIMITED_EDITION_POT: 'Limited Edition Pot',
}

export const isValidProductTrait = (trait) =>
  Object.values(PRODUCT_TRAITS).includes(trait)

export const PRODUCT_TRAITS_DATA = {
  [PRODUCT_TRAITS.PET_FRIENDLY]: {
    text: PRODUCT_TRAITS.PET_FRIENDLY,
    Icon: PetFriendly2,
  },
  [PRODUCT_TRAITS.PET_FRIENDLY_V2]: {
    text: PRODUCT_TRAITS.PET_FRIENDLY_V2,
    Icon: PetFriendly2,
  },
  [PRODUCT_TRAITS.LOW_LIGHT]: {
    text: PRODUCT_TRAITS.LOW_LIGHT,
    Icon: LowLight,
  },
  [PRODUCT_TRAITS.LIMITED_EDITION]: {
    text: PRODUCT_TRAITS.LIMITED_EDITION,
    Icon: LimitedEdition,
  },
  [PRODUCT_TRAITS.LIMITED_EDITION_POT]: {
    text: PRODUCT_TRAITS.LIMITED_EDITION_POT,
    Icon: LimitedEdition,
    textColor: theme.colors2.white,
    backgroundColor: theme.colors2.green100,
  },
}
