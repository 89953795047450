import { useEffect } from 'react'
import {
  getUTMParamsFromUrl,
  setUTMParamsInSessionStorage,
} from 'services/utmParamsService'

export function useUTMParamsStorage() {
  useEffect(() => {
    const utmParams = getUTMParamsFromUrl()

    if (Object.keys(utmParams).length !== 0) {
      setUTMParamsInSessionStorage(utmParams)
    }
  })
}
