import { browserName, getUA } from 'react-device-detect'

export const BROWSER_NAMES = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  CHROME: 'Chrome',
  SAFARI: 'Safari',
}

export function getUserBrowser() {
  const userAgent = getUA
  if (userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1) {
    return BROWSER_NAMES.FACEBOOK
  } else if (userAgent.indexOf('Instagram') > -1) {
    return BROWSER_NAMES.INSTAGRAM
  }

  return browserName
}

export function isSmallScreen() {
  return window.innerWidth < 650
}

export function isBotUserAgent() {
  return /bot|googlebot|crawler|spider|robot|crawling/i.test(getUA)
}
