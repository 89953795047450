import Helpers from '@lib/utils/helpers'
import { POT_MATERIAL_TYPES } from '../consts/pots'
import { Product, ProductVariant } from 'shopify-buy'

export const isVariantSKUASet = (sku: string) => sku.startsWith('set_')
export const isVariantSKUAGiftCard = (sku: string) => sku.startsWith('epgc_')
export const getPotMaterialTypeBySKU = (sku: string) =>
  isVariantSKUAMelaminePot(sku)
    ? POT_MATERIAL_TYPES.MELAMINE
    : POT_MATERIAL_TYPES.CERAMIC
const isVariantSKUAMelaminePot = (sku: string) => sku.includes('-LA-')

// For sets with stands option the stands variations will configured as extra selected option (type of style)
//  with the variations of the stands options

export const enum SET_STYLES_OPTIONS_INDEX {
  COLOR = 0,
  STYLE = 1,
}

export const isVariantAvailableInSomeGroup = (
  variant: ProductVariant,
  product: Product
) =>
  Helpers.isSetIncludeStandsVariants(product) &&
  product.variants.some(
    (v) =>
      v.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value ===
        variant.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value &&
      v.availableForSale
  )

export const findVariantAvailableInSpecificGroup = (
  variant: ProductVariant,
  product: Product,
  group: string
) =>
  Helpers.isSetIncludeStandsVariants(product) &&
  product.variants.find(
    (v) =>
      v.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value ===
        variant.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value &&
      v.selectedOptions[SET_STYLES_OPTIONS_INDEX.STYLE].value === group &&
      v.availableForSale
  )

export const findFirstOptionAvailable = (product: Product) =>
  // @ts-ignore
  product.options[SET_STYLES_OPTIONS_INDEX.STYLE].values[0].value

export const findLastOptionAvailableForVariant = (
  variant: ProductVariant,
  product: Product
) =>
  Helpers.isSetIncludeStandsVariants(product) &&
  product.variants.findLast(
    (v) =>
      v.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value ===
        variant.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value &&
      v.availableForSale
  )

export const getVariantsInDefaultStyle = (
  variants: ProductVariant[],
  product: Product
) => {
  const defaultStyle = findFirstOptionAvailable(product)

  return variants.filter(
    (variant) =>
      variant.selectedOptions[SET_STYLES_OPTIONS_INDEX.STYLE].value ===
      defaultStyle
  )
}

export const sortArrayToMatch = (
  referenceArray: ProductVariant[],
  arrayToSort: ProductVariant[]
) => {
  const referenceMap = new Map()
  referenceArray.forEach((value, index) =>
    referenceMap.set(
      value?.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR]?.value,
      index
    )
  )

  arrayToSort.sort((a, b) => {
    const indexA = referenceMap.get(
      a.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value
    )
    const indexB = referenceMap.get(
      b.selectedOptions[SET_STYLES_OPTIONS_INDEX.COLOR].value
    )

    if (indexA === undefined || indexB === undefined) {
      return 0
    }

    return indexA - indexB
  })

  return arrayToSort
}

// For the variants selector by style for sets with stands (when the availability between groups change the sorting is change thats why we added extra sort)
export const getVariantsByStyleSorted = (
  variants: ProductVariant[],
  product: Product,
  styleGroup?: string
) => {
  const isSetIncludeStands = Helpers.isSetIncludeStandsVariants(product)

  if (!isSetIncludeStands) {
    return variants
  }

  const filteredVariantsByStyle = variants.filter(
    (variant) =>
      variant.selectedOptions[SET_STYLES_OPTIONS_INDEX.STYLE].value ===
      styleGroup
  )

  const refGroupVariants = getVariantsInDefaultStyle(variants, product)

  return sortArrayToMatch(refGroupVariants, filteredVariantsByStyle)
}
