import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'
import { post } from '../backendService'
import { v4 as uuid } from 'uuid'
import Cookies from 'js-cookie'

export const TIKTOK_EVENT_NAMES: Record<string, string> = {
  CLICK_BUTTON: 'ClickButton',
  CATEGORY_CLICKED: 'Search',
  PRODUCT_CLICKED: 'ViewContent',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  ADD_TO_CART: 'AddToCart',
  INITIATE_CHECKOUT: 'InitiateCheckout',
}

declare global {
  interface Window {
    ttq: any
  }
}

export default class TiktokService {
  trackTiktokEvent(
    eventName: string,
    eventProperties: Record<string, any> = {}
  ) {
    if (!window.ttq) {
      return
    }

    const email = LocalStorage.getUserEmail()?.toLowerCase()
    const userId = LocalStorage.getUserId()
    const eventId = uuid()
    window.ttq.identify({
      external_id: userId,
      email: email,
    })

    window.ttq.track(eventName, eventProperties, { event_id: eventId })
    post('/tiktok/track', {
      eventName,
      eventTime: Math.floor(Date.now() / 1000), // UTC+0 Unix timestamp in seconds
      eventId,
      email,
      userId,
      properties: eventProperties,
      ttp: Cookies.get('_ttp'),
      ttclid: Cookies.get('ttclid'),
    }).catch((err) => {
      console.error(
        `Tiktok S2S event failed: ${err.message}, event: ${eventName}`,
        err
      )
    })
  }

  trackTiktokOfflineEvent(
    eventName: string,
    { email },
    eventProperties: Record<string, any>
  ) {
    const eventId = uuid()

    post('/tiktok/track-offline', {
      eventName,
      eventTime: Math.floor(Date.now() / 1000), // UTC+0 Unix timestamp in seconds
      eventId: eventId,
      email,
      properties: eventProperties,
    }).catch((err) => {
      console.error(
        `Tiktok offline S2S event failed: ${err.message}, event: ${eventName}`,
        err
      )
    })
  }

  trackAddToCart({ id, value, currency }) {
    this.trackTiktokEvent(TIKTOK_EVENT_NAMES.ADD_TO_CART, {
      content_type: 'product',
      contents: [
        {
          content_id: id,
          quantity: 1,
          price: Number(value),
          currency,
        },
      ],
      value: Number(value),
      currency,
    })
  }
}
