import { useContext } from 'react'
import { Context } from '../Context'

import { useGetLineItem } from './useGetLineItem'

export function useUpdateItemQuantity() {
  const { client, cart, setCart, setCartLoading } = useContext(Context)
  const getLineItem = useGetLineItem()

  async function updateItemQuantity(
    lineItemId: string | number,
    quantity: number
  ) {
    if (lineItemId == null) {
      throw new Error('Must provide a line item id')
    }

    if (quantity == null || Number(quantity) < 0) {
      throw new Error('Quantity must be greater than 0')
    }

    const lineItem = getLineItem(lineItemId)
    if (lineItem == null) {
      throw new Error(`Item with lineItemId ${lineItemId} not in cart`)
    }

    setCartLoading(true)
    try {
      const newCart = await client.checkout.updateLineItems(cart.id, [
        { id: lineItem.id, quantity },
      ])
      setCart(newCart)
    } catch (error) {
      console.error('Failed updating item quantity in cart', error)
    }
    setCartLoading(false)
  }

  type itemProp = {
    lineItemId: string
    quantity: number
  }
  async function updateItemsQuantity(items: itemProp[]) {
    if (
      items.some(
        (item) =>
          !item.lineItemId || item.quantity == null || Number(item.quantity) < 0
      )
    ) {
      throw new Error('Must provide a line item id')
    }

    items.forEach((item) => {
      const lineItem = getLineItem(item.lineItemId)
      if (lineItem == null) {
        throw new Error(`Item with lineItemId ${item.lineItemId} not in cart`)
      }
    })

    setCartLoading(true)
    try {
      const newCart = await client.checkout.updateLineItems(
        cart.id,
        items.map((item) => ({
          id: item.lineItemId,
          quantity: item.quantity,
        }))
      )
      setCart(newCart)
    } catch (error) {
      console.error('Failed updating item quantity in cart', error)
    }
    setCartLoading(false)
  }

  return { updateItemQuantity, updateItemsQuantity }
}
