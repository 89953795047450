import styled from 'styled-components'

const Spacer = styled.span<{ size: number }>`
  display: block;
  width: ${({ size }) => size}px;
  min-width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
`

export default Spacer
