import styled, { css, keyframes } from 'styled-components'
import { LAYOUT_DESKTOP_THRESHOLD } from '@utils/styleUtils'

export const animateOut_4 = keyframes`
0% {
  transform: translateY(64px);
  opacity: 0;
}
10%, 32% {
  transform: translateY(0);
  opacity: 1;
}
40%, 100% {
  transform: translateY(-64px);
  opacity: 0;
}
`

const animateOut_3 = keyframes`
0% {
  transform: translateY(64px);
  opacity: 0;
}
7%, 37% {
  transform: translateY(0);
  opacity: 1;
}
50%, 100% {
  transform: translateY(-64px);
  opacity: 0;
}
`

export const animateOut_2 = keyframes`
0% {
  transform: translateY(64px);
  opacity: 0;
}
7%, 47% {
  transform: translateY(0);
  opacity: 1;
}
60%, 100% {
  transform: translateY(-64px);
  opacity: 0;
}
`

interface AnimatedWrapperProps {
  $index: number
  $length: number
}

export const AnimatedWrapper = styled.div<AnimatedWrapperProps>`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateY(64px);

  ${({ $index, $length }) => {
    if ($length === 4)
      return css`
        animation: ${animateOut_4} 16s linear infinite;
        animation-delay: ${$index * 3.95}s;
      `
    if ($length === 3)
      return css`
        animation: ${animateOut_3} 11s linear infinite;
        animation-delay: ${$index * 3.7}s;
      `
    if ($length === 2)
      return css`
        animation: ${animateOut_2} 6.9s linear infinite;
        animation-delay: ${$index * 3.7}s;
      `
  }}
`

export const AnnouncementBarContainer = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 34px;
  margin-bottom: 6px;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  @media only screen and (min-width: 1160px) {
    box-shadow: none;
  }
`

export const Title = styled.div`
  font-family: Mixtiles Sans;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: white;
  // transition: all 0.8s ease-in-out;
`

interface ContainerProps {
  $fontColor?: string
  $backgroundColor?: string
  $hasTitle: boolean
}

export const Container = styled('div')<ContainerProps>`
    overflow: hidden;
    background-color: ${({ $backgroundColor, theme }) =>
      $backgroundColor || theme.colors.green};
    margin: 0 auto;
    height: ${({ $hasTitle }) => ($hasTitle ? '54px' : '34px')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: height 0.2s linear;

    &.fixed-position {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }
    &.expanded {
        height: ${({ $hasTitle }) => ($hasTitle ? '54px' : '34px')};
    }

    &.collapsed {
        height: 0px;
    }


    p {
        color: ${({ $fontColor }) => $fontColor || 'white'};
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 0.25px;
        font-family: Mixtiles Sans;
        // on Mobile
        @media only screen and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
           font-size: 13.5px;
        }
`
