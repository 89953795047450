import React, { CSSProperties, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'
import { styled, SxProps } from '@mui/material/styles'

// We should never use StyledAnchor individually.
// All links in our app should be a Next/Link.
// We only use the StyledAnchor component inside our StyledLink component
const StyledAnchor = styled('a')`
  color: ${({ color, theme }) => color || theme.colors.green};
  text-decoration: none;
`

interface StyledLinkProps extends LinkProps {
  style?: CSSProperties
  color?: string
  onClick?: () => void
  target?: string
  href: string
  rel?: string
  sx?: SxProps
  children: ReactNode
}

const StyledLink = React.forwardRef<HTMLAnchorElement, StyledLinkProps>(
  (
    { style, children, color, onClick, as, ...linkProps }: StyledLinkProps,
    ref
  ) => (
    <Link passHref {...linkProps} legacyBehavior>
      <StyledAnchor
        color={color}
        style={style}
        onClick={onClick}
        ref={ref}
        {...linkProps}
      >
        {children}
      </StyledAnchor>
    </Link>
  )
)

StyledLink.displayName = 'StyledLink'

export default StyledLink
