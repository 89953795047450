export const PAGES = {
  HOMEPAGE: 'homepage',
  ABOUT: 'about',
  FAQ: 'faq',
  CARE: 'care',
  CARE_PLANT: 'care plant',
  TERMS: 'terms',
  PRIVACY: 'privacy',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  HUGE: 'huge',
  HOLIDAYS: 'holidays',
  COLLECTIONS: 'collections',
  TREE_FORM: 'tree-form',
  PRODUCT: 'product',
  ONBOARDING: 'onboarding',
  REFERRAL: 'referral',
  GIFTCARD: 'giftcard',
  GIFTING: 'gifting',
  BLOG: 'blog',
  BLOGS: 'blogs',
  B2B: 'b2b',
  DISCOUNT: 'discount',
  PAGE_NOT_FOUND: '404 Page not found',
  STANDS: 'stands',
  EMAIL_CAPTURE: 'email capture',
  STORES: 'stores',
  RETAIL_OVERVIEW: 'retail overview',
  RETAIL_LANDING_PAGE: 'retail landing page',
}

export const PAGE_ROUTES = {
  [PAGES.HOMEPAGE]: '/',
  [PAGES.ABOUT]: '/about',
  [PAGES.FAQ]: '/faq',
  [PAGES.TERMS]: '/terms',
  [PAGES.CARE_PLANT]: '/care',
  [PAGES.PRIVACY]: '/privacy',
  [PAGES.ONBOARDING]: '/onboarding',
  [PAGES.REFERRAL]: '/refer',
  [PAGES.GIFTCARD]: '/giftcard',
  [PAGES.GIFTING]: '/gifting',
  [PAGES.BLOG]: '/blog',
  [PAGES.BLOGS]: '/blogs',
  [PAGES.B2B]: '/corporate-gifts',
  [PAGES.SMALL]: 'products/small',
  [PAGES.MEDIUM]: 'products/medium',
  [PAGES.LARGE]: 'products/large',
  [PAGES.HUGE]: 'products/huge',
  [PAGES.TREE_FORM]: 'products/tree-form',
  [PAGES.COLLECTIONS]: 'products/collections',
  [PAGES.HOLIDAYS]: 'products/holidays',
  [PAGES.STANDS]: 'products/plant-stand',
  [PAGES.EMAIL_CAPTURE]: '/email-capture',
  [PAGES.STORES]: '/stores',
  [PAGES.RETAIL_OVERVIEW]: '/retail',
}

export enum PRODUCT_PAGE_BACK_BUTTON_STRATEGIES {
  DEFAULT = 'default',
  PRODUCT_FEED = 'product-feed',
  PLANT_CARE = 'plant-care',
}

export const getRouteURL = (pageName) => PAGE_ROUTES[pageName]
