import Config from '@config/config'
import * as FullStory from '@fullstory/browser'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'
import analyticsManager from './analyticsManager'

/*
  We use this class to load fullstory only after user performed a certain action for the first time (like tap Create a Set).
  This is used to avoid using quota for simple pitch page visits.
*/

const FULL_STORY_EASYPLANT_PERCENTAGES = 0.2

export default class FullStoryService {
  constructor({ orgId }) {
    this.orgId = orgId
    this.shouldAutoLoad = LocalStorage.getShouldLoadStoryStatus()
    this.loaded = false
    this.pages = {}
    this.user = {}

    if (this.shouldAutoLoad) {
      this.loadFullstoryIfNotLoaded()
    }
  }

  init() {
    this.loadFullstoryIfNotLoaded('Homepage', FULL_STORY_EASYPLANT_PERCENTAGES)
  }

  checkIfShouldLoad(page, enabledProbability) {
    /* in order to enable Fullstory coverage for a full month with the limit of 500K sessions,
     * we want to enable recording only on limited percentage of the users. Different pages might
     * have different loading probability - for example, sessions that reached Add to Cart page
     * are more interesting. */
    if (this.pages[page]) {
      return
    }
    this.pages[page] = true
    const rand = Math.random()
    const shouldLoad = rand < enabledProbability

    analyticsManager.track('Check If Should Load Fullstory', {
      Page: page,
      'Should Load': shouldLoad,
    })
    return shouldLoad
  }

  loadFullstoryIfNotLoaded(page = null, enabledProbability = 0) {
    try {
      if (this.loaded) {
        return
      }

      if (page && !this.checkIfShouldLoad(page, enabledProbability)) {
        return
      }

      if (!this.shouldAutoLoad) {
        // Once we load Fullstory once for a user, we should auto load next time
        LocalStorage.setShouldLoadStoryStatus('true')
      }

      this.loaded = true

      FullStory.init({
        orgId: this.orgId,
        devMode: !Config.isProduction,
      })
    } catch (e) {
      console.error('Error while loading FullStory.', e)
    }
  }

  identifyUser(userId) {
    // @ts-ignore
    if (!window.FS?.identify) {
      console.log('Fullstory skipped initialization for this user', userId)
      return
    }

    // @ts-ignore
    window.FS.identify(userId)
  }

  setEmail(email) {
    this.updateUser({ key: 'email', value: email })
  }

  setUserName(name) {
    this.updateUser({ key: 'displayName', value: name })
  }

  updateUser({ key, value }) {
    this.user[key] = value
    // @ts-ignore
    if (!window.FS?.setUserVars) {
      console.log('Fullstory skipped setUserVars for this user', { key, value })
      return
    }

    // @ts-ignore
    window.FS.setUserVars(this.user)
  }
}
