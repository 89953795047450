import { useEffect } from 'react'
import Script from 'next/script'
import userService from '../../services/userService'
import NavigationService from 'services/navigationService'

const handleRouteChange = () => {
  pageView()
}

const pageView = () => {
  window.fbq('track', 'PageView')
}

const MetaPixel = ({ metaPixelId }) => {
  useEffect(() => {
    // the below will only fire on route changes (not initial load - that is handled in the script below)
    return NavigationService.subscribeToPageChanges(handleRouteChange)
  }, [])

  const advancedMatchingData = {
    external_id: userService.getUserId(),
  }
  if (userService.getUserEmail()) {
    advancedMatchingData.em = userService.getUserEmail()
  }

  return (
    <Script id='facebook-pixel' strategy='afterInteractive'>
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${metaPixelId}, ${JSON.stringify(advancedMatchingData)});
        fbq('track', 'PageView');
      `}
    </Script>
  )
}

export default MetaPixel
