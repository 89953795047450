import { post } from '../backendService'

export const MAILING_LISTS = {
  CANADA_MAILING_LIST_ID: 'UNEtTS',
  B2B_MAILING_LIST_ID: 'RF6H7g',
  GENERAL: 'TuaQqq',
}

export default class KlaviyoService {
  constructor({ companyId }) {
    this.companyId = companyId
    this.email = null
  }

  init() {}

  track(eventName, eventProps) {
    this._push('track', eventName, this._addIdentityProperties(eventProps))
  }

  identifyUser(userId) {
    this.userId = userId
    this.setUserProperties()
  }

  setEmail(email) {
    this.email = email
    this.setUserProperties()
  }

  setUserProperties(properties = {}) {
    this._push('identify', this._addIdentityProperties(properties))
  }

  _push(...params) {
    window._learnq?.push([...params])
  }

  _addIdentityProperties(properties) {
    // (!) IMPORTANT: Copy the properties so it won't affect other analytics (and send the email there too)
    properties = Object.assign({}, properties)

    if (this.userId) {
      properties.UserId = this.userId
      // We want to have a unique bucket for each user, so we take the first digit of the userId. Users will now be bucketed in 10 different buckets. (0-9)
      properties.FirstDigitOfUserId = extractFirstDigit(this.userId)
    }

    if (this.email) {
      properties.$email = this.email
    }

    return properties
  }

  // Per this guide: https://help.klaviyo.com/hc/en-us/articles/115005080407-Integrate-with-Shopify#how-to-add-viewed-product-tracking-12
  trackViewedProduct(plantName, variant) {
    const item = this._createKlaviyoShopifyItem(plantName, variant)
    if (!item) return null

    this.track('Viewed Product', item)
    this._push(
      'trackViewedItem',
      this._addIdentityProperties({
        Title: item.ProductName,
        ItemId: item.ProductID,
        ImageUrl: item.ImageURL,
        Url: item.URL,
        Metadata: {
          Price: item.Price,
          SKU: item.sku,
        },
      })
    )
  }

  trackAddToCart({ name, variant }) {
    const item = this._createKlaviyoShopifyItem(name, variant)
    this.track('Added to Cart', item)
  }

  _createKlaviyoShopifyItem(plantName, variant) {
    if (!variant) return null

    return {
      ProductName: `${plantName} - ${variant.title}`,
      ProductID: variant.id,
      ImageURL: variant.image?.src,
      Price: variant.price.amount,
      URL: window.location.href,
      SKU: variant.sku,
    }
  }

  addEmailToMailingList({
    email,
    firstName,
    lastName,
    listId,
    ...extraParams
  }) {
    return post('/klaviyo/subscribe', {
      email,
      firstName,
      lastName,
      listId,
      ...extraParams,
    }).catch((err) => {
      console.error('Failed to add email to main list', err)
    })
  }
}

function extractFirstDigit(str) {
  const match = str.match(/\d/)
  return match ? parseInt(match[0]) : null
}
