import styled from 'styled-components'

export const StyledTalkToUsIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.black};
  background-image: url(/chat.svg);
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`
