const IS_AUTHENTICATED = 'epIsAuthenticated'
const AMPLITUDE_SESSION_ID = 'amplitudeSessionId'
const UTM_PARAMS_KEY = 'utmParams'
const IRC_CLICK_ID = 'irclickid'
const LAST_SEEN_CATEGORY = 'epLastSeenCategory'
const LAST_VISITED_PLANT_HANDLE = 'lastVisitedPlantHandle'
const GIFTING_COLLECTIONS_SHOWN_AMOUNT = 'giftingCollectionsShownAmount'

export const SessionStorageKeys = {
  IS_AUTHENTICATED,
  AMPLITUDE_SESSION_ID,
  UTM_PARAMS_KEY,
  IRC_CLICK_ID,
  LAST_SEEN_CATEGORY,
  LAST_VISITED_PLANT_HANDLE,
  GIFTING_COLLECTIONS_SHOWN_AMOUNT,
}
