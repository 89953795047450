import * as Sentry from '@sentry/react'
import { UserExperiments } from '../experiments/types'

export default class SentryService {
  user: { [key: string]: string }
  preReportHandlers: { [errorKey: string]: ErrorHandler }

  constructor() {
    this.user = {}
    this.preReportHandlers = {}
  }

  identifyUser(userId) {
    this.updateUser({ key: 'id', value: userId })
  }

  setEmail(email) {
    this.updateUser({ key: 'email', value: email })
  }

  setUserName(name) {
    this.updateUser({ key: 'username', value: name })
  }

  updateUser({ key, value }) {
    this.user[key] = value
    Sentry.setUser(this.user)
  }

  setAllExperimentData(userExperiments: UserExperiments) {
    Sentry.setTags(
      Object.fromEntries(
        Object.entries(userExperiments).map(([name, variant]) => [
          `experiment.${name}`,
          variant,
        ])
      )
    )
  }

  setExperimentData({ feature, value }: { feature: string; value: string }) {
    Sentry.setTag(`experiment.${feature}`, value)
  }

  subscribeToPreErrorReportHandling(errorKey: string, handler: ErrorHandler) {
    this.preReportHandlers[errorKey] = handler
  }

  async onError(error: unknown): Promise<boolean> {
    const errorMessage =
      typeof error === 'string' ? error : (error as Error).message
    const errorKey = Object.keys(this.preReportHandlers).find(
      (errorKey) => errorMessage?.indexOf(errorKey) > -1
    )
    return errorKey ? this.preReportHandlers[errorKey](errorKey, error) : true
  }
}

type ErrorHandler = (errorKey: string, error: unknown) => boolean
