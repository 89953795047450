import React, { ReactNode } from 'react'
import * as S from '@components/SideMenu/AccordionSideItem/AccordionSideItem.styles'
import * as SideMenuStyles from '@components/SideMenu/SideMenu.styles'
import AccordionDetails from '@mui/material/AccordionDetails'

interface AccordionSideItemProps {
  open: boolean
  onClick: () => void
  subMenuItems: SubMenuItemProps[]
  children: ReactNode
}

export type SubMenuItemProps = {
  onClick: (e) => void
  children: ReactNode
}

export const AccordionSideItem: React.FC<AccordionSideItemProps> = ({
  open,
  subMenuItems,
  onClick,
  children,
}) => (
  <S.SideMenuAccordion
    onClick={onClick}
    expanded={open}
    TransitionProps={{
      timeout: {
        enter: 400,
        exit: 500,
      },
    }}
    disableGutters={true}
    sx={{
      border: 'none',
      boxShadow: 'none !important',
      '&:before': {
        backgroundColor: 'transparent !important',
      },
      padding: '0px',
    }}
  >
    <S.SideMenuAccordionSummary expandIcon={<S.ArrowSign />}>
      {children}
    </S.SideMenuAccordionSummary>
    <AccordionDetails>
      <S.SideMenuAccordionDetailsContainer>
        {subMenuItems.map((subMenuItem, index) => (
          <SideMenuStyles.Link key={index} onClick={subMenuItem.onClick}>
            {subMenuItem.children}
          </SideMenuStyles.Link>
        ))}
      </S.SideMenuAccordionDetailsContainer>
    </AccordionDetails>
  </S.SideMenuAccordion>
)
