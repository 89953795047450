const EVENT_NAMES = {
  TALK_TO_US_CLICKED: 'Talk To Us Clicked',
  MENU_ICON_CLICKED: 'Menu Icon Clicked',
  CART_ICON_CLICKED: 'Cart Icon Clicked',
  CHECKOUT_CLICKED: 'Checkout Clicked',
  CHECKOUT_CLICKED_SYNC: 'Checkout Clicked Sync',
  CLOSE_CART_CLICKED: 'Close Cart Clicked',
  REMOVE_FROM_CART: 'Remove From Cart',
  CONTINUE_SHOPPING_CLICKED: 'Continue Shopping Clicked',
  CART_QUANTITY_CLICKED: 'Cart Quantity Clicked',
  HEADER_CLICKED: 'Header Clicked',
  HEADER_BACK_CLICKED: 'Header Back Clicked',
  HEADER_LOGO_CLICKED: 'Header Logo Clicked',
  FAQ_QUESTION_EXPAND: 'FAQ Question Expand',
  PRODUCT_PAGE_SECTION_EXPAND: 'Production Page Section Expand',
  PLANT_CARE_FAQ_QUESTION_EXPAND: 'Plant Care FAQ Question Expand',
  FOOTER_CLICKED: 'Footer Clicked',
  ADD_TO_CART_CLICKED: 'Add To Cart Clicked',
  PRODUCT_COLOR_CLICKED: 'Product Color Clicked',
  PRODUCT_STAND_CLICKED: 'Product Stand Clicked',
  PRODUCT_CLICKED: 'Product Clicked',
  MOBILE_MENU_LOGO_CLICKED: 'Mobile Menu Logo Clicked',
  MOBILE_MENU_CLICKED: 'Mobile Menu Clicked',
  MIGHT_ALSO_LIKED_CLICKED: 'Might Also Liked Clicked',
  FREQUENTLY_BOUGHT_TOGETHER_LINK_CLICKED:
    'Frequently bought together variant link clicked',
  MODAL_CLOSED: 'Modal Closed',
  HOMEPAGE_SIZE_CLICKED: 'Homepage Size Clicked',
  HOMEPAGE_SHOP_PANTS_CLICKED: 'Homepage Shop Plants Clicked', // typo but save it for backward compatability
  HOMEPAGE_SHOP_PLANTS_CLICKED: 'Homepage Shop Plants Clicked',
  ABOUT: 'About',
  SHOP_PLANTS_CLICKED: 'Shop Plants Clicked',
  PLANT_CARE_CLICKED: 'Plant Care Clicked',
  B2B_CLICKED: 'B2B Clicked',
  STORES_CLICKED: 'stores Clicked',
  PLANT_CARE_SEARCH: 'Plant Care Search',
  FAQ_CLICKED: 'FAQ Clicked',
  HOME_CLICKED: 'Home Clicked',
  PRIVACY_CLICKED: 'Privacy Clicked',
  TERMS_CLICKED: 'Terms Clicked',
  WATCH_BRAND_VIDEO_CLICKED: 'Watch Brand Video Clicked',
  CLOSE_BRAND_VIDEO_CLICKED: 'Close Brand Video Clicked',
  SHOP_PAY_MODAL_CLICKED: 'Shop Pay Modal Clicked',
  STANDS_GUIDE_DRAWER: 'Stands guide drawer Clicked',
  STANDS_GUIDE_DRAWER_PICK_STAND: 'Stands guide drawer picked stand',
  EMAIL_CAPTURED: 'Email Captured',
  EMAIL_CAPTURE_POPUP_OPENED: 'Email Capture Popup Opened',
  EMAIL_CAPTURE_POPUP_CLOSED: 'Email Capture Popup Closed',
  EMAIL_CAPTURE_CORRECTION_SHOW: 'Show Email Capture Corrections',
  EMAIL_CAPTURE_CORRECTION_CLICKED: 'Email Capture Correction Clicked',
  EMAIL_CAPTURE_CLOSED: 'Email Capture Closed',
  SCREEN_VIEW: 'Screen View',
  SCREEN_VIEWED_DELAYED: 'Screen Viewed Delayed',
  PLANT_PAGE_COLLECTION_CLICKED: 'Plant Page Product Clicked',
  ONBOARDING_QUESTION_ANSWERED: 'Onboarding Question Answered',
  ONBOARDING_FUNNEL_CLICKED: 'Onboarding Funnel Clicked',
  NAME_CAPTURED: 'Name Captured',
  GIFT_NOTE_CLICKED: 'Gift Note Clicked',
  GIFT_CARD_CLICKED_FROM_PRODUCT_PAGE_FOOTER:
    'Gift card clicked from product page footer',
  PLANT_CARE_PLANT_CLICKED: 'Plant Care Plant Clicked',
  PLANT_CARE_PLANT_SECTION_CLICKED: 'Plant Care Plant Section Clicked',
  BLOG_ARTICLE_CLICKED: 'Blog article Clicked',
  BLOG_RELATED_ARTICLE_CLICKED: 'Blog related article Clicked',
  BLOG_ARTICLE_PRODUCT_CLICKED: 'Product Clicked from blog article',
  EXPERIMENTS_FORCED: 'Experiments Forced',
  GIFTCARD_SELECTED: 'Giftcard Selected',
  COLLECTIONS_GRID_CLICKED: 'Collections Grid Clicked',
  PRODUCT_FEED_FILTERS_RESET: 'Product Feed Filters Reset',
  HERO_VIDEO_PLAYED: 'Hero Video Played',
  PAUSED_HERO_VIDEO_NOW_PLAYING: 'Paused Hero Video Now Playing',
  FOOTER_SOCIAL_LINK_CLICKED: 'Footer Social Link Clicked',
  HERO_PLAY_BUTTON_CLICKED: 'Hero Play Button Clicked',
  HERO_VIDEO_SECONDS_WATCHED: 'Hero Video Seconds Watched',
  PLANTS_UPSELLER_CLICKED: 'Plants Upseller Clicked',
  UPSELL_DISCOUNT_APPLIED: 'Upsell Discount Applied',
  EXPERIMENT_ACTIVATED: 'Experiment Activated',
  CLICK_SHOP_HOLIDAY_SELECTION: 'Click Shop Holiday Selection',
  B2B_MAKE_REQUEST_LINK_CLICKED: 'B2B Make Request Link Clicked',
  B2B_MAKE_FORM_SUBMIT_CLICKED: 'B2B form submit clicked',
  ANNOUNCEMENT_BAR_CLICKED: 'Announcement Bar Link Clicked',
  GIFTING_COLLECTION_SHOW_MORE_CLICKED: 'Gifting Collection Show More Clicked',
  GIFTING_COLLECTION_TAB_CLICKED: 'Gifting Collection Tab Clicked',
  GIFTING_GIFT_A_CART_BUTTON_CLICKED: 'Gifting Gift A Cart Button Clicked',
  GIFTING_HASSLE_FREE_GREENER_BTN_CLICKED:
    'Gifting Hassle Free Greener Button Clicked',
  PRODUCT_PAGE_CLICK_ON_EXTRA_DETAILS_LINK:
    'Product Page Click On Extra Details Link',
  NEXT_ROUTING_ERROR_OCCURRED: 'Next Routing Error Occurred',
  NEXT_ROUTING_THROTTLED: 'Next Routing Throttled',
  NEXT_ROUTING_ERROR_TO_DROP: 'Next Routing Error To Drop',
  SENTRY_ERROR_REPORTED: 'Sentry Error Reported',
  AMPLITUBE_EXPERIMENT_INITIALIZE: 'Amplitude Experiment Initialization',
  STANDS_SIZE_SELECTOR_CLICKED: 'Stand Size Selector Clicked',
  STANDS_POT_SELECTOR_CLICKED: 'Stand Pot Selector Clicked',
  EXPERIMENT_ASSIGNED: 'Experiment Assigned [EPTEST]',
  EXPERIMENT_EXPOSED: 'Experiment Exposed [EPTEST]',
  PRODUCTS_WIDGET_LOADED: 'Products Widget Loaded',
  CLICKED_GET_DIRECTION: 'Clicked Get Direction',
}

export default EVENT_NAMES
