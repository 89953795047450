import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import ResizeObserver from 'resize-observer-polyfill'

export const LAYOUT_DESKTOP_THRESHOLD = 1025
export const LAYOUT_MOBILE_ORIGIN_WIDTH = 393
export const LAYOUT_MOBILE_MAX_WIDTH = 414
export const LAYOUT_DESKTOP_ORIGIN_WIDTH = 1440

export const StyleScalerBase = `
  body {
    --desktop-layout-max-width: ${LAYOUT_DESKTOP_THRESHOLD}px;
    --mobile-width: ${LAYOUT_MOBILE_ORIGIN_WIDTH}px;
    --desktop-width: ${LAYOUT_DESKTOP_ORIGIN_WIDTH}px;
  }
`

export const StyleScaler = `
  overflow: visible;
  margin: 0;
  transform: scale(var(--view-scale, 1));
  transform-origin: top left;

  @media only screen and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    min-width: var(--mobile-width);
    max-width: var(--mobile-width);
    width: var(--mobile-width);
  }

  @media only screen and (min-width: ${LAYOUT_DESKTOP_THRESHOLD}px) and (max-width: ${LAYOUT_DESKTOP_ORIGIN_WIDTH}px) {
    min-width: var(--desktop-width);
    max-width: var(--desktop-width);
    width: var(--desktop-width);
  }

  @media only screen and (min-width: ${LAYOUT_DESKTOP_ORIGIN_WIDTH}px) {
     transform: none;
  }
`

let observer

export const useScaleObserver = () => {
  const [mainViewElement, setMainViewElement] = useState(null)
  const [scaledElement, setScaledElement] = useState(null)
  const router = useRouter()

  const updateScaledView = () => {
    if (mainViewElement && !scaledElement)
      return mainViewElement.style.removeProperty('height')

    if (!scaledElement || !mainViewElement) return

    const { height } = scaledElement.getBoundingClientRect()

    if (!height) return

    mainViewElement.style.setProperty(
      'height',
      `${height.toFixed(2).toString()}px`
    )
  }

  const onResize = () => {
    const viewWidth = window.innerWidth
    const isDesktop = viewWidth >= LAYOUT_DESKTOP_THRESHOLD

    if (viewWidth >= LAYOUT_DESKTOP_ORIGIN_WIDTH) {
      document.body.style.setProperty('--view-scale', '1')
    } else {
      document.body.style.setProperty(
        '--view-scale',
        (
          viewWidth /
          (isDesktop ? LAYOUT_DESKTOP_ORIGIN_WIDTH : LAYOUT_MOBILE_ORIGIN_WIDTH)
        )
          .toFixed(5)
          .toString()
      )
    }

    updateScaledView()
  }

  useEffect(() => {
    // @ts-ignore
    observer = new ResizeObserver(() => onResize(), { box: 'border-box' })

    try {
      observer.observe(scaledElement)
    } catch (e) {}

    window.addEventListener('resize', onResize)

    setMainViewElement(document.body.querySelector('main'))
    setScaledElement(document.body.querySelector('[data-scaled="scaled"]'))
  }, [])

  useEffect(() => {
    // @ts-ignore
    observer = new ResizeObserver(() => onResize(), { box: 'border-box' })
    onResize()

    try {
      observer.observe(scaledElement)
    } catch (e) {}
  }, [scaledElement, mainViewElement])

  useEffect(() => {
    setMainViewElement(document.body.querySelector('main'))
    setScaledElement(document.body.querySelector('[data-scaled="scaled"]'))
  }, [router.asPath])

  try {
    const _scaledElement = document.body.querySelector('[data-scaled="scaled"]')

    if (!scaledElement && _scaledElement) {
      setScaledElement(_scaledElement)
    }
  } catch (e) {}

  return () => {
    try {
      observer.unobserve(scaledElement)
      window.removeEventListener('resize', onResize)
    } catch (e) {}
  }
}

export const getGlobalScale = () => {
  return Math.max(
    0,
    parseFloat(document.body.style.getPropertyValue('--view-scale')) || 0
  )
}
