import React from 'react'
import { useUTMParamsStorage } from 'hooks/useUTMParamsStorage'
import { useOnInitialLoad } from '../../hooks/useOnInitialLoad'
import { useUrlParamInStorageSession } from 'hooks/useUrlParamInStorageSession'
import { SessionStorageKeys } from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'

export const InitialCartAttributesLoader = ({ userId }) => {
  useOnInitialLoad(userId)

  useUTMParamsStorage()
  useUrlParamInStorageSession(SessionStorageKeys.IRC_CLICK_ID) // for Impact Radius Integration

  return null
}
