import React, { useEffect, useState } from 'react'
import { useLargeScreen } from '@config/Theme/theme'
import { useUI } from '../ui/context'
import * as S from './SideMenu.styles'
import Close from '@components/icons/Close'
import { Logo } from '@components/icons'
import StyledLink from '@components/common/StyledLink/StyledLink'
import { getRouteURL, PAGES } from '../../consts/pages'
import Helpers from '@lib/utils/helpers'
import EVENT_NAMES from 'consts/eventNames'
import { CART_SLIDE_ANIMATION_TIME } from '@components/ui/EasyplantDrawer/EasyplantDrawer'
import { getCurrentYear } from '@utils/dateUtils'
import analyticsManager from '../../services/analytics/analyticsManager'
import navigationLinks from '../../config/navigation.json'
import { useDefaultCollectionsURL } from '../../hooks/useDefaultCollectionsURL'
import { isGiftingAccessViewer } from '@lib/utils/gifting-access/gifting-access-viewer'
import { SupportSideMenu } from '@components/SideMenu/SupportSideMenu'
import { useHomepageEmailCaptureButton } from 'hooks/useHomepageCTAButton'

let { sideMenu: originalSideMenuLinks } = navigationLinks

const onSideMenuItemClick = ({
  href,
  title,
}: {
  href?: string
  title: string
}) => {
  analyticsManager.track(EVENT_NAMES.MOBILE_MENU_CLICKED, { href, title })
}

export default function SideMenu() {
  const isLargeScreen = useLargeScreen()
  const { displaySideMenu, closeSideMenu } = useUI()
  const shopPlantsLink = useDefaultCollectionsURL()
  const [supportOpened, setSupportOpened] = useState(false)
  const { redirectToEmailCapture, emailCaptureLink } =
    useHomepageEmailCaptureButton()
  const [sideMenuLinks, setSideMenuLinks] = useState(originalSideMenuLinks)

  sideMenuLinks[0].link = redirectToEmailCapture
    ? emailCaptureLink(shopPlantsLink)
    : shopPlantsLink

  useEffect(() => {
    const isGiftingViewer = isGiftingAccessViewer()
    const giftingAlreadyAddedToMenu = sideMenuLinks.some(
      (x) => x.title === 'Gifting'
    )
    if (isGiftingViewer && !giftingAlreadyAddedToMenu) {
      originalSideMenuLinks = [
        ...originalSideMenuLinks.slice(0, 2),
        {
          link: '/gifting',
          title: 'Gifting',
        },
        ...originalSideMenuLinks.slice(2),
      ]
      setSideMenuLinks(originalSideMenuLinks)
    }
  }, [])

  useEffect(() => {
    if (redirectToEmailCapture) {
      const newLinks = originalSideMenuLinks.map(({ title, link }) => ({
        title,
        link: emailCaptureLink(link),
      }))
      setSideMenuLinks(newLinks)
    } else {
      setSideMenuLinks(originalSideMenuLinks)
    }
  }, [redirectToEmailCapture])

  // If the screen became large and the menu is open, close it down
  useEffect(() => {
    if (isLargeScreen && displaySideMenu) {
      closeSideMenu()
    }
  }, [isLargeScreen, displaySideMenu, closeSideMenu])

  const openIntercomChat = () => {
    analyticsManager.track(EVENT_NAMES.MOBILE_MENU_CLICKED, {
      title: 'Chat With Us',
    })

    closeSideMenu()
    Helpers.openIntercomChat('Mobile Menu')
  }

  const onMenuItemClick = ({ href, title }) => {
    onSideMenuItemClick({ title, href })
    closeSideMenu()
    setSupportOpened(false)
  }

  const onSupportAccordionClick = () => {
    onSideMenuItemClick({ title: 'Support' })
    setSupportOpened(!supportOpened)
  }

  const onSubMenuItemClick = (e, navigation) => {
    e.stopPropagation()
    onSideMenuItemClick(navigation)
    setSupportOpened(false)
  }

  return (
    <S.SideMenuDrawer
      anchor={'left'}
      open={displaySideMenu}
      onClose={closeSideMenu}
      SlideProps={{
        timeout: CART_SLIDE_ANIMATION_TIME,
      }}
    >
      <S.Paper>
        <S.Header>
          <StyledLink
            onClick={() => onMenuItemClick({ href: '/', title: 'logo' })}
            href={'/'}
          >
            <Logo />
          </StyledLink>
          <Close onClick={closeSideMenu} height='16px' width='16px' />
        </S.Header>
        <S.Links>
          {sideMenuLinks.map((l) => {
            return (
              <S.Link
                key={l.title}
                onClick={() =>
                  onMenuItemClick({ href: l.link, title: l.title })
                }
              >
                <S.LinkText href={l.link} color='white'>
                  {l.title}
                </S.LinkText>
              </S.Link>
            )
          })}

          <SupportSideMenu
            open={supportOpened}
            onMainItemClick={onSupportAccordionClick}
            onSubItemClick={onSubMenuItemClick}
            openIntercomChat={openIntercomChat}
          />
        </S.Links>
        <S.Footer>
          <S.PrivacyAndTerms>
            <StyledLink
              style={{ fontSize: 14 }}
              color='white'
              href={getRouteURL(PAGES.PRIVACY)}
              onClick={() =>
                onMenuItemClick({
                  href: getRouteURL(PAGES.PRIVACY),
                  title: 'Privacy Policy',
                })
              }
            >
              Privacy Policy
            </StyledLink>
            <span style={{ color: '#000', fontSize: '10px' }}>•</span>
            <StyledLink
              style={{ fontSize: 14 }}
              color='white'
              href={getRouteURL(PAGES.TERMS)}
              onClick={() =>
                onMenuItemClick({
                  href: getRouteURL(PAGES.TERMS),
                  title: 'Terms',
                })
              }
            >
              Terms Of Use
            </StyledLink>
          </S.PrivacyAndTerms>
          <S.Copyright>{`Patent - Copyright © ${getCurrentYear()}, Mixtiles.com`}</S.Copyright>
        </S.Footer>
      </S.Paper>
    </S.SideMenuDrawer>
  )
}
