import Helpers from '@lib/utils/helpers'
import { SessionStorage } from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'

export function useUrlParamInStorageSession(param, storageKey) {
  const paramValue = Helpers.getURLParam(param)
  storageKey = storageKey || param
  if (paramValue) {
    SessionStorage.set(storageKey, paramValue)
  }
}
