import sha1 from 'js-sha1'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'

export default class ImpactService {
  identifyUser(userId) {
    const email = LocalStorage.getUserEmail()

    const customerData = {
      customerId: userId,
      ...(email && { customerEmail: sha1(email) }),
    }

    // @ts-ignore
    if (window?.ire) {
      window.ire('identify', customerData)
    }
  }
}
