import React from 'react'
import { useObjectState } from '../../hooks/useObjectState'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'

const initialState: UIState = {
  displayCart: false,
  displaySideMenu: false,
  openSideMenu: () => {},
  closeSideMenu: () => {},
  openCart: () => {},
  closeCart: () => {},
  userEmail: LocalStorage.getUserEmail(),
}

type UIState = {
  displayCart: boolean
  displaySideMenu: boolean
  openSideMenu: () => void
  closeSideMenu: () => void
  openCart: () => void
  closeCart: () => void
  userEmail: string
  setUserEmail?: (value: string) => void
}

export const UIContext = React.createContext(initialState)

UIContext.displayName = 'UIContext'

export const UIProvider = ({ children }) => {
  const [state, setState] = useObjectState(initialState)

  const openSideMenu = () => setState({ displaySideMenu: true })
  const closeSideMenu = () => setState({ displaySideMenu: false })

  const openCart = () => setState({ displayCart: true })
  const closeCart = () => setState({ displayCart: false })

  const setUserEmail = (email) => setState({ userEmail: email })

  const value = {
    ...state,
    openCart,
    closeCart,
    openSideMenu,
    closeSideMenu,
    setUserEmail,
  }

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export const useUI = (): UIState => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}
