const TAGS = {
  OOS: 'oos',
  OOS_FLORIDA: 'oos_fl',
  HIDE_IN_FEED: 'hide_in_feed',
  WITH_WOODEN_STAND: 'with_wooden_stand',
  CYBER: 'cyber',
  HOLIDAYS: 'holidays',
  EARLY_ACCESS: 'early_access',
  STAND: 'stand',
  INCLUDES_STAND: 'includes_stand',
  INCLUDES_STAND_VARIANTS: 'includes_stand_variants',
  SET: 'set',
  STAND_BUNDLE_OFFER: 'stand_bundle_offer',
}

export default TAGS
