import Script from 'next/script'

const GrinAffiliates = () => (
  <Script id='grin-affiliates' strategy='afterInteractive'>
    {`
     (function () {Grin = window.Grin || (window.Grin = []);var s = document.createElement('script');s.type = 'text/javascript';s.async = true;s.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js';var x = document.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);})();
    `}
  </Script>
)

export default GrinAffiliates
