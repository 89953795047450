import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'
import { ExperimentName, UserExperiments } from '../experiments/types'

const FORCED_VARIATION_SEARCH_PREFIX = 'FORCE_'
const FORCED_VARIANTS_SEARCH_PREFIX_REGEX = /^FORCE_/i

export const FORCE_EXPERIMENT_REASON = {
  existingUser: 'Existing user',
  queryParam: 'Url parameters',
}

const FORCED_VARIANTS_STORAGE_KEY = 'forcedVariants'

export function forceQueryParamVariations() {
  const forcedExperiments = findForcedExperimentsInQueryParams()
  if (forcedExperiments.length > 0) {
    const forcedVariations = forcedExperiments.reduce(
      (acc, { experimentKey, experimentVariation }) => {
        acc[experimentKey] = experimentVariation
        return acc
      },
      {}
    )

    LocalStorage.set(
      FORCED_VARIANTS_STORAGE_KEY,
      JSON.stringify(forcedVariations)
    )
    forcedVariationsCache = forcedVariations

    return forcedVariationsCache
  }
}

/**
 * Finds all the url query params of the format ?OPTY_experiment_name=variation_name
 * @return {{experimentKey: string, experimentVariation: string}[]}
 */
function findForcedExperimentsInQueryParams() {
  const result = []
  const urlSearchParams = new URLSearchParams(window.location.search)

  for (const [name, value] of urlSearchParams.entries()) {
    if (!FORCED_VARIANTS_SEARCH_PREFIX_REGEX.test(name)) {
      continue
    }

    const experimentKey = name.substr(FORCED_VARIATION_SEARCH_PREFIX.length)
    const experimentVariation = value

    result.push({ experimentKey, experimentVariation })
  }

  return result
}

let forcedVariationsCache = null

function initForceVariations() {
  forcedVariationsCache = JSON.parse(
    LocalStorage.get(FORCED_VARIANTS_STORAGE_KEY) || '{}'
  )
}

export function getForceVariations(
  featureName: ExperimentName
): UserExperiments[ExperimentName] {
  if (!forcedVariationsCache) {
    initForceVariations()
  }

  return forcedVariationsCache[featureName]
}
