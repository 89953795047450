import { Agatha, Amber, Era, Monet, Yoko } from '@components/icons/stands'

export const POT_COLORS = {
  CALM_ROSE: 'Calm Rose',
  DEEP_OCEAN: 'Deep Ocean',
  DEEP_NAVY: 'Deep Navy',
  SHINY_EGGWHITE: 'Shiny EggWhite',
  MUSTARD_YELLOW: 'Mustard',
  TURQUOISE: 'Turquoise',
  DESERT_BROWN: 'Desert Brown',
  WINTER_CLOUDS: 'Winter Clouds',
  WHITE: 'White',
  BLACK: 'Black',
  ALMOND_GREEN: 'Almond Green',
  ETERNAL_GRAY: 'Eternal Gray',
  OLIVE: 'Olive',
  DARK_OLIVE: 'Dark Olive',
  GENTLE_PINK: 'Gentle Pink',
  CREAM: 'Cream',

  // Agatha
  STONE_BLACK: 'Stone Black',
  SANDY_PINK: 'Sandy Pink',
  TWILIGHT_BLUE: 'Twilight Blue',
  IRON_GRAY: 'Iron Gray',
  SUNSET_ORANGE: 'Sunset Orange',
  EARTH_RED: 'Earth Red',
  SAGE_GREEN: 'Sage Green',
  NATURAL_BAMBOO: 'Natural Bamboo',

  // Yoko
  GLOSSY_RED: 'Glossy Red',
  GLOSSY_PINK: 'Glossy Pink',
  KEY_LIME_YELLOW: 'Key Lime Yellow',
  MISTY_BLUE: 'Misty Blue',
  REACTIVE_AQUA: 'Reactive Aqua',
  COTTON_WHITE: 'Cotton White',

  // special editions
  WAVY_PEACH: 'Hammered Peach Pink',
  WAVY_ROSE: 'Hammered Rose Pink',
  STRIPS_PEACH: 'Striped Peach Pink',
  STRIPS_ROSE: 'Striped Rose Pink',
  STRIPS_RED: 'Striped Cherry Red',
}

export const WHITE_POT_COLORS = [
  POT_COLORS.SHINY_EGGWHITE,
  POT_COLORS.CREAM,
  POT_COLORS.WHITE,
  POT_COLORS.COTTON_WHITE,
]

export const POT_MATERIAL_TYPES = {
  CERAMIC: 'ceramic',
  MELAMINE: 'melamine',
}

export const POT_TYPES = {
  AMBER: 'Amber',
  ERA: 'Era',
  MONET: 'Monet',
  TUSCA: 'Tusca',
  AGATHA: 'Agatha',
  YOKO: 'Yoko',
}

export const POT_ICONS = {
  [POT_TYPES.AMBER]: (size: 16 | 32) => <Amber width={size} height={size} />,
  [POT_TYPES.ERA]: (size: 16 | 32) => <Era width={size} height={size} />,
  [POT_TYPES.MONET]: (size: 16 | 32) => <Monet width={size} height={size} />,
  [POT_TYPES.AGATHA]: (size: 16 | 32) => <Agatha width={size} height={size} />,
  [POT_TYPES.TUSCA]: (size: 16 | 32) => <Monet width={size} height={size} />,
  [POT_TYPES.YOKO]: (size: 16 | 32) => <Yoko width={size} height={size} />,
}
