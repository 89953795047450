import type { AttributeInput, CheckoutLineItemInput } from 'shopify-buy'
import { useAddItemsToCart } from './useAddItemsToCart'

export function useAddItemToCart() {
  const addItemsToCart = useAddItemsToCart()

  async function addItemToCart(
    variantId: string,
    quantity: number,
    customAttributes?: AttributeInput[]
  ) {
    const item: CheckoutLineItemInput[] = [
      { variantId, quantity, customAttributes },
    ]

    return addItemsToCart(item)
  }

  return addItemToCart
}
