import styled from 'styled-components'
import Accordion from '@mui/material/Accordion'
import { AccordionSummary } from '@mui/material'
import { FaqArrowCollapse as Arrow } from '@components/icons'
import StyledLink from '@components/common/StyledLink/StyledLink'

export const ArrowSign = styled(Arrow)`
  > path {
    stroke: white;
  }
`

export const SideMenuAccordionDetailsContainer = styled('div')`
  padding-left: 16px;
`

export const SideMenuAccordion = styled(Accordion)`
  color: #fff;
  background: ${({ theme }) => theme.colors.green};
  padding: 0;
  margin-top: -12px;

  .MuiAccordionDetails-root {
    padding-left: 0;
  }

  .MuiAccordionDetails-root {
    padding-top: 18px;
    padding-bottom: 0px;
  }
`

export const SideMenuAccordionSummary = styled(AccordionSummary)`
  margin-block: 0;
  padding-inline: 0;
  display: flex;
`

export const SubMenuStyledLink = styled(StyledLink)`
  display: block;
`
