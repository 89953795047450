import React, { useMemo } from 'react'
import { AccordionSideItem } from '@components/SideMenu/AccordionSideItem/AccordtionSideItem'
import * as S from '@components/SideMenu/AccordionSideItem/AccordionSideItem.styles'
import { useHomepageEmailCaptureButton } from 'hooks/useHomepageCTAButton'

interface SideMenuItem {
  title: string
  href?: string
}

const SUPPORT_SUB_ITEMS = {
  BLOG: 'blog',
  CARE: 'care',
  FAQ: 'faq',
  HELP_CENTER: 'help_center',
  INTERCOM: 'intercom',
}

const SUPPORT_SUB_ITEMS_NAVIGATION: Record<string, SideMenuItem> = {
  [SUPPORT_SUB_ITEMS.FAQ]: {
    title: 'FAQ',
    href: '/faq',
  },
  [SUPPORT_SUB_ITEMS.HELP_CENTER]: {
    title: 'Help Center',
    href: 'https://help.easyplant.com/en/',
  },
  [SUPPORT_SUB_ITEMS.BLOG]: {
    title: 'Blog',
    href: '/blog',
  },
  [SUPPORT_SUB_ITEMS.INTERCOM]: {
    title: 'Chat With Us',
  },
}

interface SupportSideMenuProps {
  open: boolean
  onMainItemClick: () => void
  onSubItemClick: (e: React.MouseEvent, navigation: SideMenuItem) => void
  openIntercomChat: () => void
}

export const SupportSideMenu: React.FC<SupportSideMenuProps> = ({
  open,
  onMainItemClick,
  onSubItemClick,
  openIntercomChat,
}) => {
  const { redirectToEmailCapture, emailCaptureLink } =
    useHomepageEmailCaptureButton()

  const supportMenuItems = useMemo(
    () =>
      Object.entries(SUPPORT_SUB_ITEMS_NAVIGATION).map(([key, navigation]) => {
        if (key === SUPPORT_SUB_ITEMS.INTERCOM) {
          return {
            onClick: () => openIntercomChat(),
            children: <span>Chat With Us</span>,
          }
        } else {
          return {
            onClick: (e) => onSubItemClick(e, navigation),
            children: (
              <S.SubMenuStyledLink
                href={
                  redirectToEmailCapture
                    ? emailCaptureLink(navigation.href)
                    : navigation.href
                }
                color='white'
                target='_blank'
              >
                {navigation.title}
              </S.SubMenuStyledLink>
            ),
          }
        }
      }),
    [onSubItemClick, openIntercomChat]
  )

  return (
    <AccordionSideItem
      open={open}
      subMenuItems={supportMenuItems}
      onClick={onMainItemClick}
    >
      Support
    </AccordionSideItem>
  )
}
