import { createTheme } from '@mui/material'
import { createBreakpoints } from '@mui/system'
import BREAKPOINTS, { getMediaQueryHelpers } from './breakpoints'
import { useTheme as useMuiTheme } from '@mui/material/styles'
import { ThemeOptions } from '@mui/material/styles/createTheme'

interface ThemeOverrides {
  colors: {
    [key: string]: string
  }
  sizes: {
    [key: string]: {
      width?: number
      height?: number
    }
  }
}

declare module '@mui/material/styles' {
  interface Theme extends ThemeOverrides {}
  // allow configuration using `createTheme`
  interface ThemeOptions extends Partial<ThemeOverrides> {}
}

const PRIMARY_BLACK = '#41342d'
const PRIMARY_GREEN = '#375437'
const BACKGROUND = '#FFFCF9'

const breakpoints = createBreakpoints({ values: BREAKPOINTS })

let theme = createTheme({
  colors: {
    white: '#FFFFFF',
    black: PRIMARY_BLACK,
    green0: '#304A30',
    green: PRIMARY_GREEN,
    green1: '#40644C',
    green2: '#4E734E',
    green3: '#47634E',
    green4: '#90A195',
    green5: '#DFE9D8',
    green6: '#3F6449',
    green7: '#EDF0ED',
    green8: '#266B37',
    pink: '#E6645C',
    pink2: '#EF887E',
    pink3: '#F5AEA9',
    pink4: '#F6CFBE',
    pink5: '#F8ECE4',
    brown: '#0E0D0C',
    brown2: '#C8B19A',
    brown3: '#EFE6D9',
    brown4: '#A69F97',
    cream: '#F5F1EA',
    cream2: '#FDF9F0',
    cream3: BACKGROUND,
    cream4: '#E8E3DA',
    cream5: '#F5F0E6',
    cream6: '#D7D2C8',
    cream7: '#FAF7F0',
    mustard: '#FAB842',
    mustard2: '#FBCE7D',
    mustard3: '#FBE0AF',
    red: '#E7655C',
    red2: '#AA2634',
    gray: '#E8E4DD',
    gray2: '#756F69',
    gray3: '#57534E',
    gray4: '#D3D3D3',
    lightGray: '#EAECED',
    lightGray2: '#E8E3DA',
    darkGray: '#171615',
    black2: '#252423',
    black3: '#171615',
    lightBlue: '#B4C5CF',
    lightBlue2: '#F0F3F4',
  },
  colors2: {
    black: '#000000',
    white: '#FFFFFF',
    red100: '#F7DFD0',
    red200: '#F8B195',
    red300: '#E7655C',
    green50: '#EDF0ED',
    green100: '#90A195',
    green200: '#47634E',
    green300: '#375437',
    green400: '#266B37',
    yellow100: '#FFF1D6',
    yellow200: '#FFE299',
    black20: '#FFFCF9',
    black50: '#FAF7F0',
    black100: '#F5F0E6',
    black200: '#E8E3DA',
    black300: '#D7D2C8',
    black400: '#A69F97',
    black500: '#756F69',
    black600: '#57534E',
    black700: '#45413E',
    black800: '#292725',
    black900: '#171615',
    blue50: '#F0F3F4',
    blue100: '#C0D4DB',
    blue200: '#9BC1CE',
  },
  sizes: {
    navigation: {
      width: 1140,
      height: 56,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_BLACK,
    },
    secondary: {
      main: PRIMARY_GREEN,
    },
    background: {
      default: BACKGROUND,
    },
    text: {
      primary: PRIMARY_BLACK,
    },
  },
  breakpoints,
  typography: {
    fontFamily: ['Fraunces', 'serif'].join(','),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          borderRadius: '4px',
          textTransform: 'none',
          fontSize: '18px',
          fontWeight: 600,
          width: '100%',
          padding: '15px',
          border: 0,
          outline: 0,
        },
        containedSecondary: {
          backgroundColor: `${PRIMARY_GREEN} !important`,
          ':hover': {
            background:
              'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,.1))',
            boxShadow: '0 3px 15px 1px rgba(0, 0, 0, 0.09)',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#F4F4F4',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0 auto;
          position: relative;
          font-family: Fraunces, serif !important;
          padding: 0;
          width: 100vw;

          ${breakpoints.up('lg')} {
            max-width: none;
            width: 100%;
          }
        }

        // add spacing for the FAB if there is one on the page
        ${breakpoints.down('lg')} {
          body:has([data-has-fab="true"]) footer,
          body:has([data-testid="cta-mobile"]) footer {
            margin-bottom: 82px;
          }
        }

        html {
          -webkit-font-smoothing: auto;
        }

        * {
            -webkit-tap-highlight-color: transparent;
        }

        .animated {
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }

        .fadeIn {
          -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
        }

        @-webkit-keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        *, *:before, *:after {
          box-sizing: border-box;
        }

        button, input {
          font-family: 'Fraunces', serif;
        }

        button {
          all: unset;
        }

        a {
          text-decoration: none;
        }

        .builder-block a {
            text-decoration: underline;
            color: ${PRIMARY_GREEN};
        }

        ${breakpoints.up('lg')} {
          .modal-video-body {
            max-width: 100%;
          }
        }
      `,
    },
  },
})

export const { onMediumScreen, onLargeScreen, useLargeScreen } =
  getMediaQueryHelpers(theme)

export const useTheme = useMuiTheme<ThemeOptions>
export default theme

export const FONTS = {
  MIXTILES_SANS: 'Mixtiles Sans',
  FRAUNCS: 'Fraunces',
}
