import type { CheckoutLineItem } from 'shopify-buy'

interface LineItemPricingResult {
  priceBeforeDiscount: number
  priceAfterDiscount: number
}

interface CalculateLineItemPriceProps {
  lineItem: CheckoutLineItem
  isDiscountRound?: boolean
}

export const calculateLineItemTotalDiscount = (
  lineItem: CheckoutLineItem
): number => {
  const { discountAllocations, variant } = lineItem

  return discountAllocations.length > 0
    ? discountAllocations.reduce((totalAppliedDiscount, appliedDiscount) => {
        let discount = Number(appliedDiscount.allocatedAmount.amount)

        return totalAppliedDiscount + discount
      }, 0)
    : 0
}

export const calculateLineItemPrice = ({
  lineItem,
  isDiscountRound,
}: CalculateLineItemPriceProps): LineItemPricingResult => {
  const { variant, quantity } = lineItem
  const singleLineItemPrice = variant.price.amount

  const appliedDiscount = calculateLineItemTotalDiscount(lineItem)

  const priceBeforeDiscount = quantity * singleLineItemPrice
  let priceAfterDiscount = priceBeforeDiscount - appliedDiscount

  if (isDiscountRound) {
    priceAfterDiscount = Math.round(priceAfterDiscount)
  }

  return {
    priceBeforeDiscount,
    priceAfterDiscount,
  }
}

const createPriceFormatter = (alwaysShowDecimalPoints) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: alwaysShowDecimalPoints ? 2 : 0,
    maximumFractionDigits: 2,
  })

export const displayPriceFormat = (
  price: number,
  alwaysShowDecimalPoints = false
) => {
  return createPriceFormatter(alwaysShowDecimalPoints).format(price)
}
