import React from 'react'
import { useLargeScreen, useTheme } from '@config/Theme/theme'
import { Stack } from '@mui/material'
import { Box } from '@mui/system'
import styled from 'styled-components'
import { LAYOUT_DESKTOP_THRESHOLD } from '@utils/styleUtils'

const NavBarContainer = styled.div`
  @media only screen and (max-width: ${LAYOUT_DESKTOP_THRESHOLD}px) {
    padding-inline: 16px;
  }
`

function Navbar({ leftComponent, centerComponent, rightComponent, sx = {} }) {
  const theme = useTheme()
  const isDesktop = useLargeScreen()

  return (
    <NavBarContainer>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height={isDesktop ? theme.sizes.navigation.height : 44}
        maxWidth={theme.sizes.navigation.width}
        mx='auto'
        sx={sx}
      >
        <Box sx={{ flex: 1 }}>{leftComponent}</Box>

        {centerComponent}

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {rightComponent}
        </Box>
      </Stack>
    </NavBarContainer>
  )
}

export default Navbar
