import {
  MetaPixel,
  Gtag,
  GoogleTagManager,
  Impact,
  Klaviyo,
  GrinAffiliates,
} from '@components/analytics/index'
import Config from '@config/config'
import TiktokPixel from '@components/analytics/TiktokPixel'
import SnapchatPixel from './SnapchatPixel'
import LinkedInPixel from './LinkedInPixel'

const {
  analytics: {
    gtagId,
    gtmId,
    metaPixelId,
    klaviyoCompanyId,
    loadImpact,
    tiktokPixelId,
    snapchatPixelId,
    linkedInPartnerId,
  },
} = Config

const AppAnalytics = () => {
  return (
    <>
      {gtagId && <Gtag gtagId={gtagId} />}
      {gtmId && <GoogleTagManager gtmId={gtmId} />}
      {metaPixelId && <MetaPixel metaPixelId={metaPixelId} />}
      {klaviyoCompanyId && <Klaviyo companyId={klaviyoCompanyId} />}
      {loadImpact && <Impact />}
      {tiktokPixelId && <TiktokPixel tiktokPixelId={tiktokPixelId} />}
      {snapchatPixelId && <SnapchatPixel snapchatPixelId={snapchatPixelId} />}
      {linkedInPartnerId && (
        <LinkedInPixel linkedInPartnerId={linkedInPartnerId} />
      )}
      <GrinAffiliates />
    </>
  )
}

export default AppAnalytics
