import Helpers from '@lib/utils/helpers'
import env from '@config/config'

const BASE_URL = env.isProduction
  ? 'https://easyplant.com'
  : 'http://localhost:3000'

export function urlParamContains(param, substrings) {
  const urlParam = Helpers.getURLParam(param)
  if (!urlParam) {
    return false
  }
  return substrings.some((substring) =>
    urlParam.toLowerCase().includes(substring)
  )
}

export function getFullURL(path) {
  return `${BASE_URL}${path}`
}
