import analyticsManager from '../../services/analytics/analyticsManager'
import { ANALYTICS_SERVICES_NAMES } from '../../services/analytics/analyticsConfig'
import { PRODUCT_TRAITS } from '@components/ProductTraits/traitsData'
import { LIGHT_MODES } from './lightInstructionsHelpers'
import env from '../../config/config'
import { getFeaturedCollections } from '../../consts/collections'
import { capitalizeFirstLatter } from '@utils/stringUtils'
import EVENT_NAMES from '../../consts/eventNames'
import { displayPriceFormat } from '@utils/pricingUtils'
import TAGS from 'consts/tags'

const sizes = Object.values(getFeaturedCollections())

const Helpers = {
  getURLParam: (key) => {
    if (typeof window !== 'undefined') {
      const match = RegExp('[?&]' + key + '=([^&]*)').exec(
        window.location.search
      )
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    }
    return null
  },

  checkIfTagExists: (tagToCheck, tagsList) => {
    if (tagsList === undefined) {
      console.warn(
        'tagsList was undefined. Please make sure to check if this is intentional or that the tags were not fetched from Shopify'
      )
      tagsList = []
    }

    let exists = false
    tagsList.forEach((tag) => {
      if (tag.value === tagToCheck) {
        exists = true
      }
    })
    return exists
  },

  getPlantMeasurements: (tags) => {
    const sizeTag = tags?.find((tag) => tag.value.startsWith('size_'))
    if (!sizeTag) {
      return null
    }

    const [_, ...measurements] = sizeTag.value.split('_')
    return measurements.map((measurement) => `${measurement}"`).join('-')
  },

  getScientificNameTag: (tags) => {
    let scientificName = 'N/A'
    tags?.forEach((tag) => {
      if (tag.value.startsWith('bn_')) {
        scientificName = tag.value.replace('bn_', '').replace(/_/g, ' ')
      }
    })
    return scientificName
  },
  getPotStyleName: (attr) => {
    let potName = 'N/A'
    for (const a in attr) {
      if (attr[a].key === 'Pot') {
        potName = attr[a].value
        if (potName !== 'era with stand') {
          potName = potName.split(' ')[0]
        }
      }
    }
    return potName
  },

  displayPrice: (price, alwaysShowDecimalPoints = false) => {
    if (!price) {
      return null
    }

    if (price.amount) {
      return displayPriceFormat(price.amount, alwaysShowDecimalPoints)
    }

    if (typeof price === 'number') {
      return displayPriceFormat(price, alwaysShowDecimalPoints)
    }

    let amount = typeof price === 'object' ? price.amount : price

    return displayPriceFormat(amount, alwaysShowDecimalPoints)
  },

  openIntercomChat: (origin = 'N/A') => {
    if (!env.analytics.intercomId) {
      console.log('Missing Intercom ID. Aborting')
      return
    }

    analyticsManager.track(EVENT_NAMES.TALK_TO_US_CLICKED, { origin })
    analyticsManager.getService(ANALYTICS_SERVICES_NAMES.INTERCOM).show()
  },
  getProductTraits: ({ product, lightingInstructions }) => {
    const traits = []

    if (!product) {
      return traits
    }

    if (Helpers.checkIfTagExists('pet_friendly', product.tags)) {
      traits.push(PRODUCT_TRAITS.PET_FRIENDLY)
    }

    if (Helpers.checkIfTagExists('limited_edition', product.tags)) {
      traits.push(PRODUCT_TRAITS.LIMITED_EDITION)
    }

    if (
      Helpers.checkIfTagExists('low_light', product.tags) ||
      Helpers.isLowLightPlant(lightingInstructions?.traits)
    ) {
      traits.push(PRODUCT_TRAITS.LOW_LIGHT)
    }

    return traits
  },
  getProductSize: (product) => {
    const sizeTag = product.tags?.find((tag) => sizes.includes(tag.value))
    if (!sizeTag) {
      return null
    }
    return capitalizeFirstLatter(sizeTag.value)
  },

  isProductAvailable: (product) =>
    product.availableForSale && !Helpers.productIsArtificiallyOOS(product),
  productIsArtificiallyOOS: (product) =>
    product.tags?.find(
      (tag) => tag.value === TAGS.OOS || tag.value === TAGS.OOS_FLORIDA
    ),
  productShouldBeHiddenInFeed: (product) =>
    Helpers.checkIfTagExists(TAGS.HIDE_IN_FEED, product.tags),
  productShouldShowConsideringEarlyAccess: (product, showEarlyAccess) => {
    return (
      showEarlyAccess ||
      !Helpers.checkIfTagExists(TAGS.EARLY_ACCESS, product.tags)
    )
  },
  isLowLightPlant: (lightDataTraits) => {
    if (!lightDataTraits) {
      return false
    }

    const lightTraits = lightDataTraits.map((trait) => trait.trait)
    return (
      lightTraits.includes(LIGHT_MODES.LOW_LIGHT) ||
      lightTraits.includes(LIGHT_MODES.NO_NATURAL_LIGHT)
    )
  },
  isSetIncludeStands: (product) =>
    Helpers.checkIfTagExists(TAGS.INCLUDES_STAND, product.tags) &&
    Helpers.checkIfTagExists(TAGS.SET, product.tags),
  isSetIncludeStandsVariants: (product) =>
    Helpers.checkIfTagExists(TAGS.INCLUDES_STAND_VARIANTS, product.tags) &&
    Helpers.checkIfTagExists(TAGS.SET, product.tags),
}

export default Helpers
