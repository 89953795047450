import React from 'react'
import 'keen-slider/keen-slider.min.css'
import theme from '@config/Theme/theme'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { SharedLayout } from '@components/SharedLayout/SharedLayout'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import Container from '@mui/material/Container'
import '@config/builder'
import '@lib/global.css'
import 'react-modal-video/scss/modal-video.scss'
import useIntercom from '../hooks/useIntercom'
import TalkToUsIcon from '@components/TalkToUsIcon/TalkToUsIcon'
import shopifyConfig from '@config/shopify'
import { CommerceProvider } from '@lib/shopify/storefront-data-hooks'
import { InitialCartAttributesLoader } from '@components/InitialCartAttributesLoader/InitialCartAttributesLoader'
import AppAnalytics from '@components/analytics/AppAnalytics'
import 'typeface-roboto'
import { initOnAppLoad } from '../services/InitalizationService'
import Helpers from '@lib/utils/helpers'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'
import NavigationService from '../services/navigationService'

const userId = initOnAppLoad()

const forcedCountryCode = Helpers.getURLParam('forceCountry')
const forcedRegionCode = Helpers.getURLParam('forceRegion')

if (forcedCountryCode) {
  LocalStorage.setForceCountryCode(forcedCountryCode)
}
if (forcedRegionCode) {
  LocalStorage.setForceRegionCode(forcedRegionCode)
}

NavigationService.subscribeToPageRoutingErrors()

const Noop = () => <></>
const DefaultDesktopContainer = ({ children }) => (
  <Container maxWidth='xl'>{children}</Container>
)

export default function MyApp({ Component, pageProps }) {
  const Navbar = Component.Navbar || Noop
  const DesktopContainer = Component.DesktopContainer || DefaultDesktopContainer
  const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_ID
  useIntercom({ appId: intercomAppId })

  return (
    <CommerceProvider {...shopifyConfig}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <InitialCartAttributesLoader userId={userId} />
          <CssBaseline />
          <AppAnalytics />
          <SharedLayout
            pageProps={pageProps}
            Navbar={Navbar}
            DesktopContainer={DesktopContainer}
          >
            <Component {...pageProps} />
          </SharedLayout>
          {intercomAppId && <TalkToUsIcon />}
        </LocalizationProvider>
      </ThemeProvider>
    </CommerceProvider>
  )
}
