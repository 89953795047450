export const capitalizeFirstLatter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const sanitizeEmail = (email) => {
  if (!email) {
    return null
  }

  return email.trim()
}

export const isEmail = (email) => {
  const emailRegex =
    /^([\w\.-]*[a-zA-Z0-9]@([a-zA-Z0-9][\w\.-]*[a-zA-Z0-9]\.[a-zA-Z][a-zA-Z\.]*[a-zA-Z]))$/

  return emailRegex.test(email)
}
