import {
  SessionStorage,
  SessionStorageKeys,
} from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'
import Helpers from '@lib/utils/helpers'
import { useEffect } from 'react'
import { ANALYTICS_SERVICES_NAMES } from 'services/analytics/analyticsConfig'
import analyticsManager from 'services/analytics/analyticsManager'
import userService from 'services/userService'
import { useCart, useCheckout } from '@lib/shopify/storefront-data-hooks'

export const useOnInitialLoad = (userId) => {
  const { cart } = useCart()
  const checkout = useCheckout()

  const isUserAuthenticated = SessionStorage.isUserAuthenticated()

  async function updateInitialCartAttributes() {
    try {
      if (!cart) {
        return
      }

      const refreshedCart = await checkout.fetch(String(cart.id))

      if (!refreshedCart) {
        return
      }
      const cartHasBeenPurchased = Boolean(refreshedCart.completedAt)

      if (cartHasBeenPurchased) {
        return
      }

      const amplitudeService = analyticsManager.getService(
        ANALYTICS_SERVICES_NAMES.AMPLITUDE
      )
      let amplitudeSessionId = amplitudeService.getSessionId()
      if (amplitudeSessionId) {
        SessionStorage.setAmplitudeSessionId(amplitudeSessionId)
      } else {
        const amplitudeSessionIdFromStorage =
          SessionStorage.getAmplitudeSessionId()
        if (amplitudeSessionIdFromStorage) {
          amplitudeService.setSessionId(amplitudeSessionIdFromStorage)
          amplitudeSessionId = amplitudeSessionIdFromStorage
        }
      }

      const ircclickId = Helpers.getURLParam(SessionStorageKeys.IRC_CLICK_ID)

      const checkoutAttributes = {
        customAttributes: [
          { key: 'epUserId', value: userId },
          {
            key: 'userAttributes',
            value: JSON.stringify(userService.getUserAttributes()),
          },
        ],
      }

      if (amplitudeSessionId) {
        checkoutAttributes.customAttributes.push({
          key: 'amplitudeSessionId',
          value: amplitudeSessionId.toString(),
        })
      }

      if (ircclickId) {
        checkoutAttributes.customAttributes.push({
          key: SessionStorageKeys.IRC_CLICK_ID,
          value: ircclickId.toString(),
        })
      }

      await checkout.updateAttributes(cart.id, checkoutAttributes)
    } catch (error) {
      console.error('Failed updating cart attributes', error)
    }
  }

  useEffect(() => {
    if (!isUserAuthenticated) {
      return
    }

    updateInitialCartAttributes()
  }, [isUserAuthenticated, userId, cart?.id])
}
