import { addUTMParamsToUrl } from './utmParamsService'
import {
  SessionStorage,
  SessionStorageKeys,
} from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'

export function addParamsToCheckoutUrl(checkoutURL) {
  const urlWithParams = addUTMParamsToUrl(checkoutURL)
  const ircClickId = SessionStorage.getIRCClickId()

  if (!ircClickId) {
    return urlWithParams
  }

  const url = new URL(urlWithParams)
  url.searchParams.append(SessionStorageKeys.IRC_CLICK_ID, ircClickId)

  return url.href
}
