const GTM_TRACK_EVENTS = {
  LEAD: 'Lead',
}

export default class GtmService {
  constructor({ gtmId }) {
    this.gtmId = gtmId
  }

  init() {
    window.dataLayer = window.dataLayer || []

    this._push({
      originalLocation:
        document.location.protocol +
        '//' +
        document.location.hostname +
        document.location.pathname +
        document.location.search,
    })
  }

  track(eventName, eventProperties) {
    this._push({
      event: eventName,
      props: eventProperties,
    })
  }

  trackB2BLead({ id, name, collection, value }) {
    this._push({
      event: GTM_TRACK_EVENTS.LEAD,
      props: {
        content_ids: [id],
        contents: [{ id, quantity: 1 }],
        content_name: name,
        content_category: collection,
        content_type: 'product',
        currency: 'USD',
        value,
      },
    })
  }

  identifyUser(userId) {
    this._push({
      userId,
    })
  }

  setEmail(email) {
    this._push({
      email,
    })
  }

  _push(data) {
    window.dataLayer?.push(data)
  }
}
