import { styled, css } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import StyledLink from '@components/common/StyledLink/StyledLink'

const padingSize = 30

export const SideMenuDrawer = styled(Drawer)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.colors.green};
  }
`

export const Paper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: ${padingSize}px ${padingSize}px 0 ${padingSize}px;
  color: #fff;
  position: relative;
  height: 100%;
`

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;

  // For the easyplant and X logos
  svg,
  path {
    color: #fff;
  }
`
export const Link = styled('div')`
  font-size: 17px;
  cursor: pointer;
  margin-bottom: 36px;
`
export const LinkText = styled(StyledLink)`
  width: 100%;
  display: block;
`

export const Links = styled('div')`
  display: flex;
  flex-direction: column;
`

export const Footer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: auto;
  padding-bottom: ${padingSize}px;
`

export const PrivacyAndTerms = styled('div')`
  display: flex;
  text-align: center;
  width: 100%;
  color: white;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;

  > span {
    color: white !important;
  }
`

export const Copyright = styled('div')`
  opacity: 0.8;
  font-size: 12px;
  color: white;
`
