const LINKEDIN_CONVERSION_ID = 18605329

export default class LinkedinService {
  track(eventName, eventProperties) {
    // no track event for snapchat, should send only standard events
  }

  trackLeadEvent() {
    window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID })
  }
}
