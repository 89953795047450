/*
Intercom is initialized via the useIntercom hook on _app.tsx
 */
import whitelist from './whitelist.json'

const { events: eventsWhitelist } = whitelist.intercom
const intercomEvents = new Set(eventsWhitelist)

export default class IntercomService {
  constructor() {
    this.loaded = false
  }

  updateIntercom(updateData) {
    // @ts-ignore
    window.Intercom('update', updateData)
  }

  track(eventName, eventProperties) {
    if (!intercomEvents.has(eventName)) {
      return
    }
    window.Intercom('trackEvent', eventName, eventProperties)
  }

  setUserProperties(userProperties) {
    // @ts-ignore
    if (this.loaded) {
      this.updateIntercom(userProperties)
    } else {
      // We wait 2 seconds to let the app load Intercom
      setTimeout(() => {
        // @ts-ignore
        if (window.Intercom) {
          this.updateIntercom(userProperties)
          this.loaded = true
        }
      }, 2000)
    }
  }

  identifyUser(userId) {
    this.setUserProperties({ user_id: userId })
  }

  setEmail(email) {
    this.setUserProperties({ email: email })
  }

  show() {
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom('show')
    }
  }
}
