import { getRouteURL, PAGE_ROUTES, PAGES } from '../consts/pages'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils/LocalStorage/LocalStorage'
import { useDefaultCollectionsURL } from './useDefaultCollectionsURL'
import { useUI } from '@components/ui/context'
import { isBrowser } from '@utils/runtimeUtils'
import { useEffect, useState } from 'react'
import NavigationService from 'services/navigationService'
import { getUTMParamsFromSessionStorage } from 'services/utmParamsService'

const ONBOARDING_TEXTS = {
  SHOP_PLANTS: 'Shop Plants',
  LETS_GO: "Let's Go",
}

export const useHomepageCTAButton = () => {
  const { userEmail } = useUI()
  const isOnboardingDone = LocalStorage.getIsOnboardingDone() || userEmail
  const defaultCollectionsURL = useDefaultCollectionsURL()

  return {
    isOnboardingDone,
    ctaText: isOnboardingDone
      ? ONBOARDING_TEXTS.SHOP_PLANTS
      : ONBOARDING_TEXTS.LETS_GO,
    ctaLink: isOnboardingDone
      ? defaultCollectionsURL
      : getRouteURL(PAGES.ONBOARDING),
  }
}

export const useHomepageEmailCaptureButton = () => {
  const { userEmail } = useUI()
  const utmParams = getUTMParamsFromSessionStorage()
  const isEmailCaptureShown = LocalStorage.getIsEmailCapturePopupOpened()
  const isOnboardingDone = LocalStorage.getIsOnboardingDone() || userEmail

  const [redirectToEmailCapture, setRedirectToEmailCapture] = useState(false)

  const getEmailCaptureLink = (redirectLink: string) => {
    return `${PAGE_ROUTES[PAGES.EMAIL_CAPTURE]}?redirect=${redirectLink}`
  }

  useEffect(() => {
    const handleChange = () => {
      if (
        !isEmailCaptureShown &&
        !isOnboardingDone &&
        isBrowser &&
        utmParams?.utm_source !== 'email' &&
        window.location.pathname === PAGE_ROUTES[PAGES.HOMEPAGE]
      ) {
        setRedirectToEmailCapture(true)
      } else {
        setRedirectToEmailCapture(false)
      }
    }

    handleChange()

    return NavigationService.subscribeToPageChanges(handleChange)
  }, [isEmailCaptureShown, isOnboardingDone])

  return {
    redirectToEmailCapture: redirectToEmailCapture,
    emailCaptureLink: getEmailCaptureLink,
  }
}
