import { Router } from 'next/router'
import analyticsManager from './analytics/analyticsManager'
import AnalyticsManager from './analytics/analyticsManager'
import { ANALYTICS_SERVICES_NAMES } from './analytics/analyticsConfig'
import EVENT_NAMES from '../consts/eventNames'
import SentryService from './analytics/SentryService'

const EXCEPTIONS_TO_DROP = [
  'Route did not complete loading',
  'Abort fetching component for route',
]

export enum NextRoutingThrottleType {
  IgnoreRepeatedNavigation = 'Ignore Repeated Navigation',
  IgnoreSamePageNavigation = 'Ignore Same Page Navigation',
}

export default class NavigationService {
  static subscribeToPageChanges(handleRouteChange: (url: string) => void) {
    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }

  static subscribeToPageRoutingErrors() {
    Router.events.on('routeChangeError', (error, route, transitionOptions) => {
      if (!transitionOptions?.shallow) {
        analyticsManager
          .getService(ANALYTICS_SERVICES_NAMES.AMPLITUDE)
          .track(EVENT_NAMES.NEXT_ROUTING_ERROR_OCCURRED, {
            Type: error?.message?.split(':')?.[0],
            Route: route,
          })
      }
    })

    const sentryService = AnalyticsManager.getService(
      ANALYTICS_SERVICES_NAMES.SENTRY
    ) as SentryService
    EXCEPTIONS_TO_DROP.forEach((errorType) => {
      sentryService?.subscribeToPreErrorReportHandling(
        errorType,
        (errorType) => {
          analyticsManager
            .getService(ANALYTICS_SERVICES_NAMES.AMPLITUDE)
            .track(EVENT_NAMES.NEXT_ROUTING_ERROR_TO_DROP, {
              Type: errorType,
              URL: window.location.href,
            })
          return false
        }
      )
    })
  }

  static trackNextRoutingThrottled(
    type: NextRoutingThrottleType,
    path: string
  ) {
    analyticsManager
      .getService(ANALYTICS_SERVICES_NAMES.AMPLITUDE)
      .track(EVENT_NAMES.NEXT_ROUTING_THROTTLED, {
        Type: type,
        Route: path,
      })
  }
}
