import { useContext } from 'react'
import { Context } from '../Context'

export function useRemoveItemsFromCart() {
  const { client, cart, setCart } = useContext(Context)

  async function removeItemsFromCart(lineItemIds: string[]) {
    if (cart == null || client == null) {
      throw new Error('Called removeItemsFromCart too soon')
    }

    if (lineItemIds.length < 1) {
      throw new Error('Must include at least one item to remove')
    }

    try {
      const newCart = await client.checkout.removeLineItems(
        cart.id,
        lineItemIds
      )
      setCart(newCart)
    } catch (error) {
      console.error('Failed removing item from cart', error)
    }
  }

  return removeItemsFromCart
}
