import React from 'react'
import NextHead from 'next/head'
import { DefaultSeo } from 'next-seo'
import env from '../../config/config'
import shopifyConfig from 'config/shopify'

const Head = ({ seoInfo }) => {
  const shouldIndexAndFollow = env.isProduction

  return (
    <>
      <DefaultSeo
        {...seoInfo}
        dangerouslySetAllPagesToNoIndex={!shouldIndexAndFollow}
        dangerouslySetAllPagesToNoFollow={!shouldIndexAndFollow}
      />
      <NextHead>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link
          rel='manifest'
          href='/site.webmanifest'
          key='site-manifest'
          crossOrigin='use-credentials'
        />
        <link rel='icon' href='/favicon.ico' />
        <link rel='preconnect' href={shopifyConfig.domain} />
        <link rel='dns-prefetch' href='https://fonts.gstatic.com' />
        <link rel='dns-prefetch' href='https://res.cloudinary.com' />
        <link rel='dns-prefetch' href='https://fonts.googleapis.com' />
      </NextHead>
    </>
  )
}

export default Head
