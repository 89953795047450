import dynamic from '@utils/dynamic'

export const Bag = dynamic(() => import('./Bag'))
export const Heart = dynamic(() => import('./Heart'))
export const Trash = dynamic(() => import('./Trash'))
export const Cross = dynamic(() => import('./Cross'))
export const ArrowLeft = dynamic(() => import('./ArrowLeft'))
export const Plus = dynamic(() => import('./Plus'))
export const Minus = dynamic(() => import('./Minus'))
export const Check = dynamic(() => import('./Check'))
export const Sun = dynamic(() => import('./Sun'))
export const Moon = dynamic(() => import('./Moon'))
export const Github = dynamic(() => import('./Github'))
export const DoubleChevron = dynamic(() => import('./DoubleChevron'))
export const DoubleChevronDown = dynamic(
  () => import('./DoubleChevronDown.svg')
)
export const RightArrow = dynamic(() => import('./RightArrow'))
export const Info = dynamic(() => import('./Info'))
export const ChevronUp = dynamic(() => import('./ChevronUp'))
export const Vercel = dynamic(() => import('./Vercel'))
export const Logo = dynamic(() => import('./Logo'))
export const Back = dynamic(() => import('./Back.svg'))
export const BackGreen = dynamic(() => import('./BackGreen.svg'))
export const Cart = dynamic(() => import('./Cart.svg'))
export const Down = dynamic(() => import('./Down.svg'))
export const LowLight = dynamic(() => import('./LowLight.svg'))
export const PetFriendly = dynamic(() => import('./PetFriendly.svg'))
export const PetFriendly2 = dynamic(() => import('./PetFriendly2.svg'))
export const LimitedEdition = dynamic(() => import('./LimitedEdition.svg'))
export const SelfWatering = dynamic(() => import('./self-watering.svg'))
export const Gift = dynamic(() => import('./gift.svg'))
export const Pot = dynamic(() => import('./pot.svg'))
export const GlanceLivingroom = dynamic(
  () => import('./PlantAtGlance/GlanceLivingroom.svg')
)
export const GlanceBathroom = dynamic(
  () => import('./PlantAtGlance/GlanceBathroom.svg')
)
export const GlanceBedroom = dynamic(
  () => import('./PlantAtGlance/GlanceBedroom.svg')
)
export const GlanceBlooming = dynamic(
  () => import('./PlantAtGlance/GlanceBlooming.svg')
)
export const GlancePet = dynamic(() => import('./PlantAtGlance/GlancePet.svg'))
export const GlanceLowLight = dynamic(
  () => import('./PlantAtGlance/GlanceLowLight.svg')
)
export const GlanceGrowth = dynamic(
  () => import('./PlantAtGlance/GlanceGrowth.svg')
)
export const GlanceMeasurement = dynamic(
  () => import('./PlantAtGlance/GlanceMeasurement.svg')
)
export const GlanceWatering = dynamic(
  () => import('./PlantAtGlance/GlanceWatering.svg')
)
export const PlantCareIcon = dynamic(() => import('./PlantCareIcon.svg'))
export const TestimonialsLogos = dynamic(
  () => import('./Homepage/TestimonialsLogos.svg')
)
export const HowItWorksStep1 = dynamic(() => import('./Homepage/Step1.svg'))
export const HowItWorksStep2 = dynamic(() => import('./Homepage/Step2.svg'))
export const HowItWorksStep3 = dynamic(() => import('./Homepage/Step3.svg'))
export const HomepageMobileVideoBG = dynamic(
  () => import('./Homepage/HomepageMobileVideoBG.svg')
)
export const ChatWithUsIcon = dynamic(() => import('./Homepage/ChatWithUs.svg'))
export const ArrowCollapse = dynamic(() => import('./ArrowCollapse.svg'))
export const ProductArrowCollapse = dynamic(
  () => import('./ProductArrowCollapse.svg')
)
export const FaqArrowCollapse = dynamic(() => import('./FaqArrowCollapse.svg'))
export const BubbleArrowRight = dynamic(
  () => import('./Homepage/BubbleArrowRight.svg')
)
export const BubbleArrowLeft = dynamic(
  () => import('./Homepage/BubbleArrowLeft.svg')
)
export const Star = dynamic(() => import('./Star.svg'))
export const X = dynamic(() => import('./X.svg'))
export const Plant = dynamic(() => import('./Plant.svg'))
export const GlanceLimitedEdition = dynamic(
  () => import('./PlantAtGlance/GlanceLimitedEdition.svg')
)
export const GlancePlantsAmount = dynamic(
  () => import('./PlantAtGlance/GlancePlantsAmount.svg')
)
export const GlanceDescription = dynamic(
  () => import('./PlantAtGlance/GlanceDescription.svg')
)
export const GlanceFreeShipping = dynamic(
  () => import('./PlantAtGlance/GlanceFreeShipping.svg')
)
export const GlanceHandCrafted = dynamic(
  () => import('./PlantAtGlance/GlanceHandCrafted.svg')
)
export const GlanceSimpleMaintenance = dynamic(
  () => import('./PlantAtGlance/GlanceSimpleMaintenance.svg')
)
export const GlanceHassleFreeAssembly = dynamic(
  () => import('./PlantAtGlance/GlanceHassleFreeAssembly.svg')
)
export const GlanceElevateAesthetics = dynamic(
  () => import('./PlantAtGlance/GlanceElevateAesthetics.svg')
)
export const QuestionMark = dynamic(() => import('./QuestionMark.svg'))
export const Checkmark = dynamic(() => import('./checkmark.svg'))
export const Facebook = dynamic(() => import('./facebook.svg'))
export const Instagram = dynamic(() => import('./instagram.svg'))
export const Tiktok = dynamic(() => import('./tiktok.svg'))
export const CelebrateBadge = dynamic(() => import('./celebrate.svg'))
export const FlatClose = dynamic(() => import('./flatClose.svg'))
export const MapMarker = dynamic(() => import('./MapMarker.svg'))
export const TrustPilotLogo = dynamic(() => import('./TrustPilotLogo.svg'))
export const TrustPilotStarts = dynamic(() => import('./TrustpilotStars.svg'))

export { default as Easyplant } from './PlantAtGlance/Easyplant.png'
export { default as HomepageDesktopVideoBG } from './Homepage/HomepageDesktopVideoBG.png'
export { default as DailyLogo } from './Homepage/Logos/Daily.png'
export { default as GoodHousekeepingLogo } from './Homepage/Logos/GoodHousekeeping.png'
export { default as GlamourMagazineLogo } from './Homepage/Logos/GlamourMagazine.png'
export { default as InsiderLogo } from './Homepage/Logos/Insider.png'
export { default as NbcLogo } from './Homepage/Logos/Nbc.png'
export { default as RealLogo } from './Homepage/Logos/Real.png'
export { default as SpruceLogo } from './Homepage/Logos/Spruce.png'
export { default as PopsugarLogo } from './Homepage/Logos/Popsugar.png'
