import { styled, css } from '@mui/material'

interface NavbarContainerProps {
  $stickyBarEnabled: boolean
  $showDropdown: boolean
}

export const Container = styled('header')<NavbarContainerProps>`
  background-color: ${({ theme }) => theme.colors.cream3};

  ${({ $stickyBarEnabled }) =>
    $stickyBarEnabled &&
    css`
      position: fixed;
      right: 0;
      top: 0;
      left: 0;
      z-index: 1000;
    `}

  ${({ $showDropdown }) =>
    $showDropdown &&
    css`
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    `}
`
