import { SessionStorage } from '@lib/shopify/storefront-data-hooks/src/utils/SessionStorage'

const UTM_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_campaign_id',
  'utm_adset_id',
  'utm_ad_id',
  'utm_email',
]

const UTM_PARAMS_KEY = 'utmParams'

export function isUTMParam(param) {
  return UTM_PARAMS.includes(param)
}

export function setUTMParamsInSessionStorage(utmParams) {
  return SessionStorage.setUTMParams(utmParams)
}

export function addUTMParamsToUrl(checkoutURL) {
  const utmParams = getUTMParamsFromSessionStorage()

  if (!utmParams) {
    return checkoutURL
  }

  const url = new URL(checkoutURL)
  Object.entries(utmParams).forEach(([key, value]) => {
    url.searchParams.append(key, value)
  })

  return url.href
}

export function getUTMParamsFromUrl() {
  const utmParams = {}
  const parsedUrl = new URL(window.location.href)
  for (const [key, value] of parsedUrl.searchParams) {
    if (isUTMParam(key)) {
      utmParams[key] = value
    }
  }

  return utmParams
}

export function getUTMParamsFromSessionStorage() {
  return SessionStorage.getUTMParams()
}
