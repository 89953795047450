import AmplitudeService from './AmplitudeService'
import GtmService from './GtmService'
import MetaService from './MetaService'
import KlaviyoService from './KlaviyoService'
import IntercomService from './IntercomService'
import FullStoryService from './FullStoryService'
import ImpactService from './impactService'
import Config from '../../config/config'
import SentryService from './SentryService'
import TiktokService from './TiktokService'
import SnapchatService from './SnapchatService'
import LinkedinService from './LinkedinService'

const NO_OP = () => {
  // This is intentional. Some services don't have all functions and will need a fallback behavior
}
const ANALYTICS_SERVICES_METHODS = [
  'init',
  'identifyUser',
  'setUserProperties',
  'setEmail',
  'setUserName',
  'track',
]
const {
  analytics: { amplitudeSDKKey },
} = Config

export const ANALYTICS_SERVICES_NAMES = {
  AMPLITUDE: 'amplitude',
  FULLSTORY: 'fullstory',
  GOOGLE_SITE_TAG: 'gtag',
  GOOGLE_TAG_MANAGER: 'gtm',
  INTERCOM: 'intercom',
  KLAVIYO: 'klaviyo',
  META: 'meta',
  SENTRY: 'sentry',
  IMPACT: 'impact',
  TIKTOK: 'tiktok',
  SNAPCHAT: 'snapchat',
  LINKEDIN: 'linkedin',
}

const analyticsConfig = [
  {
    name: ANALYTICS_SERVICES_NAMES.AMPLITUDE,
    service: AmplitudeService,
    config: {
      sdkKey: amplitudeSDKKey,
    },
    runInDev: true,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.META,
    service: MetaService,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.GOOGLE_TAG_MANAGER,
    service: GtmService,
    config: {
      gtmId: 'GTM-NG3T8LP',
    },
  },
  {
    name: ANALYTICS_SERVICES_NAMES.KLAVIYO,
    service: KlaviyoService,
    config: {
      companyId: 'ULjjhr',
    },
    runInDev: true,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.INTERCOM,
    service: IntercomService,
    runInDev: true,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.FULLSTORY,
    service: FullStoryService,
    config: {
      orgId: 'GNA10',
    },
  },
  {
    name: ANALYTICS_SERVICES_NAMES.SENTRY,
    service: SentryService,
    runInDev: false,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.IMPACT,
    service: ImpactService,
    runInDev: true,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.TIKTOK,
    service: TiktokService,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.SNAPCHAT,
    service: SnapchatService,
    runInDev: false,
  },
  {
    name: ANALYTICS_SERVICES_NAMES.LINKEDIN,
    service: LinkedinService,
    runInDev: false,
  },
]

export function createAnalyticsInterface({
  service: Service,
  name,
  config,
  inDev,
}) {
  const serviceInstance = new Service(config)
  serviceInstance.name = name

  if (inDev) {
    serviceInstance.inDev = inDev
  }

  // We're dependent on the fact that all of our services share the same methods. If one of our services does not have
  // one of the methods we mentioned, we fallback to a NO_OP
  for (const method of ANALYTICS_SERVICES_METHODS) {
    serviceInstance[method] = serviceInstance[method] || NO_OP
  }

  return serviceInstance
}

export default analyticsConfig
