import { useContext } from 'react'
import { Context } from '../Context'
import type { CheckoutLineItem } from 'shopify-buy'

export function useCartItems(): CheckoutLineItem[] {
  const { cart } = useContext(Context)
  if (!cart || !Array.isArray(cart.lineItems)) {
    return []
  }

  return cart.lineItems
}
