export const LIGHT_MODES = {
  DIRECT_SUNLIGHT: 'Direct Sunlight',
  WELL_LIT_SPACE: 'Well Lit Space',
  LOW_LIGHT: 'Low Light',
  NO_NATURAL_LIGHT: 'No Natural Light',
}
const LIGHT_MODE_TO_SYMBOL = {
  [LIGHT_MODES.DIRECT_SUNLIGHT]: 'A',
  [LIGHT_MODES.WELL_LIT_SPACE]: 'B',
  [LIGHT_MODES.LOW_LIGHT]: 'C',
  [LIGHT_MODES.NO_NATURAL_LIGHT]: 'D',
}

export function convertLightTraitsToImageName(traits) {
  const symbols = traits.map((trait) => LIGHT_MODE_TO_SYMBOL[trait.trait])
  return symbols.sort().join('')
}
