import env from './config'
import { SHOPIFY_API_VERSION } from '@lib/shopify/storefront-data-hooks/src/CommerceProvider'

if (!env.shopifyStoreDomain) {
  throw new Error(
    'Missing required environment variable NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN'
  )
}
if (!env.shopifyStorefrontApiToken) {
  throw new Error(
    'Missing required environment variable NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN'
  )
}

interface ShopifyConfigType {
  domain: string
  storefrontAccessToken: string
  apiVersion: string
}

export default {
  domain: env.shopifyStoreDomain,
  storefrontAccessToken: env.shopifyStorefrontApiToken,
  apiVersion: SHOPIFY_API_VERSION,
} as ShopifyConfigType
