import React, { useEffect } from 'react'
import Script from 'next/script'
import userService from 'services/userService'
import { useRouter } from 'next/router'
import { SNAPCHAT_TRACK_EVENTS } from 'services/analytics/SnapchatService'
import NavigationService from 'services/navigationService'
import analyticsManager from 'services/analytics/analyticsManager'
import { ANALYTICS_SERVICES_NAMES } from 'services/analytics/analyticsConfig'

interface SnapchatPixelProps {
  snapchatPixelId: string
}

const handleRouteChange = () => {
  pageView()
}

const pageView = () => {
  analyticsManager
    .getService(ANALYTICS_SERVICES_NAMES.SNAPCHAT)
    ?.trackSnapchatEvent(SNAPCHAT_TRACK_EVENTS.PAGE_VIEW)
}

const SnapchatPixel: React.FC<SnapchatPixelProps> = ({ snapchatPixelId }) => {
  let matchingData = undefined
  if (userService.getUserEmail()) {
    matchingData = {
      user_email: userService.getUserEmail(),
    }
  }

  const router = useRouter()

  useEffect(() => {
    // the below will only fire on route changes (not initial load - that is handled in the script below)
    return NavigationService.subscribeToPageChanges(handleRouteChange)
  }, [])

  return (
    <>
      <Script id='snapchat-pixel' strategy='afterInteractive'>
        {`
        (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
                {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
                a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
                r.src=n;var u=t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r,u);})(window,document,
                \'https://sc-static.net/scevent.min.js\');

          snaptr('init', "${snapchatPixelId}", ${
          JSON.stringify(matchingData) || undefined
        });

        snaptr('track', 'PAGE_VIEW');
     `}
      </Script>
    </>
  )
}
export default SnapchatPixel
