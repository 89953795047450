export const ENVIRONMENT_TYPES = {
  STAGING: 'staging',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
}

const runtimeEnv = process.env.NEXT_PUBLIC_APP_ENV

const Config = {
  analytics: {
    amplitudeSDKKey: process.env.NEXT_PUBLIC_AMPLITUDE_SDK_KEY,
    optimizelySDKKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
    intercomId: process.env.NEXT_PUBLIC_INTERCOM_ID,
    gtagId: process.env.NEXT_PUBLIC_GTAG_ID,
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    metaPixelId: process.env.NEXT_PUBLIC_META_PIXEL_ID,
    klaviyoCompanyId: process.env.NEXT_PUBLIC_KLAVIYO_COMPANY_ID,
    loadImpact: process.env.NEXT_PUBLIC_LOAD_IMPACT,
    tiktokPixelId: process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID,
    snapchatPixelId: process.env.NEXT_PUBLIC_SNAPCHAT_PIXEL_ID,
    linkedInPartnerId: process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID,
  },
  isDev: runtimeEnv === ENVIRONMENT_TYPES.DEVELOPMENT,
  isStaging: runtimeEnv === ENVIRONMENT_TYPES.STAGING,
  isProduction: runtimeEnv === ENVIRONMENT_TYPES.PRODUCTION,
  builderPublicKey: process.env.NEXT_PUBLIC_BUILDER_PUBLIC_KEY,
  easyplantBackendUrl: process.env.NEXT_PUBLIC_EASYPLANT_BACKEND_URL,
  shopifyStoreDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
  shopifyStorefrontApiToken:
    process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_TOKEN,
}

export default Config
