import type { Checkout } from 'shopify-buy'

export function isCart(potentialCart: any): potentialCart is Checkout {
  return (
    potentialCart != null &&
    potentialCart.id != null &&
    potentialCart.webUrl != null &&
    potentialCart.lineItems != null &&
    potentialCart.type != null &&
    potentialCart.type.name === 'Checkout' &&
    potentialCart.type.kind === 'OBJECT'
  )
}

export const checkLineItemsAvailability = (potentialCart) =>
  potentialCart.lineItems.every((lineItem) => lineItem.variant)
