import { useCartItems } from './useCartItems'

export function useGetLineItem() {
  const cartItems = useCartItems()

  function getLineItem(
    lineItemId: string | number
  ): ShopifyBuy.CheckoutLineItem | null {
    if (cartItems.length < 1) {
      return null
    }

    const item = cartItems.find((cartItem) => {
      // @ts-ignore
      return cartItem.id === lineItemId
    })

    if (item == null) {
      return null
    }

    return item
  }

  return getLineItem
}
