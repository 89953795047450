import { LocalStorageKeys } from './keys'
import { isCart } from '../../utils'
import { isBrowser, isServer } from '@utils/runtimeUtils'
import { checkLineItemsAvailability } from '@lib/shopify/storefront-data-hooks/src/utils/types/isCart'

function set(key, value) {
  if (isBrowser()) {
    try {
      window.localStorage.setItem(key, value)
    } catch {
      console.warn(' Error reading from local storage')
    }
  }
}

function get(key) {
  if (isServer()) {
    return null
  }

  try {
    const item = window.localStorage.getItem(key)
    return item
  } catch {
    return null
  }
}

function getInitialCart() {
  const existingCartString = get(LocalStorageKeys.CART)
  if (existingCartString == null) {
    return null
  }

  try {
    const existingCart = JSON.parse(existingCartString)
    if (!isCart(existingCart)) {
      return null
    }

    if (!checkLineItemsAvailability(existingCart)) {
      return null
    }

    return existingCart
  } catch {
    return null
  }
}

const getUserId = () => get(LocalStorageKeys.USER_ID)
const setUserId = (userId) => set(LocalStorageKeys.USER_ID, userId)

const getIsNewUser = () => get(LocalStorageKeys.IS_NEW_USER) === 'true'
const setIsNewUser = (value) => set(LocalStorageKeys.IS_NEW_USER, value)

const getUserName = () => get(LocalStorageKeys.USER_NAME)
const setUserName = (value) => set(LocalStorageKeys.USER_NAME, value)

const getUserEmail = () => get(LocalStorageKeys.USER_EMAIL)
const setUserEmail = (value) => set(LocalStorageKeys.USER_EMAIL, value)

const getIsOnboardingDone = () =>
  get(LocalStorageKeys.ONBOARDING_DONE) === 'true'
const setOnboardingDone = () => set(LocalStorageKeys.ONBOARDING_DONE, 'true')
const getIsEmailCapturePopupOpened = () =>
  get(LocalStorageKeys.EMAIL_CAPTURE_POPUP_OPENED) === 'true'
const setIsEmailCapturePopupOpened = () =>
  set(LocalStorageKeys.EMAIL_CAPTURE_POPUP_OPENED, 'true')

const getShouldLoadStoryStatus = () =>
  get(LocalStorageKeys.SHOULD_AUTO_LOAD_FULLSTORY)
const setShouldLoadStoryStatus = (value) =>
  set(LocalStorageKeys.SHOULD_AUTO_LOAD_FULLSTORY, value)

const getForceCountryCode = () => get(LocalStorageKeys.FORCE_COUNTRY)
const setForceCountryCode = (value) =>
  set(LocalStorageKeys.FORCE_COUNTRY, value)

const getForceRegionCode = () => get(LocalStorageKeys.FORCE_REGION)
const setForceRegionCode = (value) => set(LocalStorageKeys.FORCE_REGION, value)

const getRecentlyViewedProducts = () =>
  get(LocalStorageKeys.RECENTLY_VIEWED_PRODUCTS)
const setRecentlyViewedProducts = (value) =>
  set(LocalStorageKeys.RECENTLY_VIEWED_PRODUCTS, value)

export const LocalStorage = {
  get,
  set,
  getInitialCart,
  getUserId,
  setUserId,
  getIsNewUser,
  setIsNewUser,
  getUserName,
  setUserName,
  getUserEmail,
  setUserEmail,
  getIsOnboardingDone,
  setOnboardingDone,
  getIsEmailCapturePopupOpened,
  setIsEmailCapturePopupOpened,
  getShouldLoadStoryStatus,
  setShouldLoadStoryStatus,
  getForceCountryCode,
  setForceCountryCode,
  getForceRegionCode,
  setForceRegionCode,
  getRecentlyViewedProducts,
  setRecentlyViewedProducts,
}
