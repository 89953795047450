import { useCallback, useState } from 'react'

/*
Function returns
1) A state object
2) A function which accepts a partial state to merge
*/
export const useObjectState = (initialState) => {
  const [state, setState] = useState(initialState)

  //function which accepts a partial state to merge
  const setCustomState = useCallback((newPartialState) => {
    try {
      setState((prevState) => {
        return { ...prevState, ...newPartialState }
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  return [state, setCustomState]
}
