import { post } from 'services/backendService'
import { getFullUserAttributes } from '../Features/utils'

export const META_TRACK_EVENTS = {
  VIEW_CONTENT: 'ViewContent',
  ADD_TO_CART: 'AddToCart',
  LEAD: 'Lead',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
}

const metaEventValues = Object.values(META_TRACK_EVENTS)

export default class MetaService {
  track(eventName, eventProperties) {
    if (!window.fbq) {
      return
    }

    window.fbq('trackCustom', eventName, eventProperties)
  }

  trackMetaEvent(eventName, eventProperties = {}) {
    if (!window.fbq || !metaEventValues.includes(eventName)) {
      return
    }

    window.fbq('track', eventName, {
      ...eventProperties,
      referrer: document.referrer,
    })
  }

  trackB2BLead({ id, name, collection, value }) {
    this.trackMetaEvent(
      META_TRACK_EVENTS.LEAD,
      generateMetaParams({ id, name, collection, value })
    )
  }

  trackViewContent({ id, name, collection, value }) {
    this.trackMetaEvent(
      META_TRACK_EVENTS.VIEW_CONTENT,
      generateMetaParams({ id, name, collection, value })
    )
  }

  trackAddToCart({ id, name, collection, value }) {
    this.trackMetaEvent(
      META_TRACK_EVENTS.ADD_TO_CART,
      generateMetaParams({ id, name, collection, value })
    )
  }

  trackCompleteRegistration() {
    this.trackMetaEvent(META_TRACK_EVENTS.COMPLETE_REGISTRATION)
  }

  trackMetaOfflineEvent(
    eventName,
    eventProperties,
    { email, phoneNumber, url }
  ) {
    post('/facebook/track', {
      eventName,
      eventTime: Math.floor(Date.now() / 1000), // UTC+0 Unix timestamp in seconds
      email,
      phoneNumber,
      url,
      properties: eventProperties,
    }).catch((err) => {
      console.error(
        `Meta offline S2S event failed: ${err.message}, event: ${eventName}`,
        err
      )
    })
  }
}

function generateMetaParams({ id, name, collection, value }) {
  return {
    content_ids: [id],
    contents: [{ id, quantity: 1 }],
    content_name: name,
    content_category: collection,
    content_type: 'product',
    currency: 'USD',
    value,
  }
}
