import React, { useEffect } from 'react'
import { UIProvider } from '@components/ui/context'
import { Head } from '@components/common'
import seoConfig from '@config/seo.json'
import '@builder.io/widgets/dist/lib/builder-widgets-async'
import { RenderOnDesktop, RenderOnMobile } from '@config/Theme/sizeDisplays'
import { NavbarContainer } from '@components/common/Navbar/NavbarContainer'
import { StyleScalerBase, useScaleObserver } from '@utils/styleUtils'
import SideMenu from '../SideMenu/SideMenu'
import dynamic from '@utils/dynamic'

const DynamicCart = dynamic(() => import('../Cart/Cart'))
const DynamicFooter = dynamic(() => import('@components/Footer/Footer'))
const DynamicEmailCapturePopup = dynamic(
  () =>
    import('@components/Pages/Onboarding/EmailCapturePopup/EmailCapturePopup')
)

export function SharedLayout({
  Navbar,
  DesktopContainer,
  children,
  pageProps,
}) {
  const announcement = pageProps?.announcement
  const navbarProps = pageProps?.navbarProps || {}
  const unobserve = useScaleObserver()
  const showFooter = !!!pageProps?.hideFooter
  const showNavbar = !!!pageProps?.hideNavbar

  useEffect(() => {
    return unobserve()
  }, [])

  return (
    <UIProvider>
      <Head seoInfo={seoConfig} />
      <style>{StyleScalerBase}</style>
      {showNavbar && (
        <NavbarContainer
          Navbar={Navbar}
          announcement={announcement}
          navbarProps={navbarProps}
        />
      )}

      <DesktopContainer>
        <RenderOnDesktop>
          <main>{children}</main>
        </RenderOnDesktop>
      </DesktopContainer>
      <RenderOnMobile>
        <main>{children}</main>
      </RenderOnMobile>

      <DynamicCart />
      <SideMenu />
      <DynamicEmailCapturePopup />

      {showFooter && <DynamicFooter />}
    </UIProvider>
  )
}
