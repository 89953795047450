import { v4 as uuid } from 'uuid'
import EVENT_NAMES from '../../consts/eventNames'
import analyticsConfig, {
  ANALYTICS_SERVICES_NAMES,
  createAnalyticsInterface,
} from './analyticsConfig'
import Config from '../../config/config'
import { isBrowser } from '@utils/runtimeUtils'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'
import { isVariantSKUASet } from '../setsService'
import userService from 'services/userService'

const { isProduction } = Config

/*
  Analytics Manager is the gateway to our analytics. It's a singleton instance and it instantiates all of our analytics services
  devMode means that only services that specified should "runInDev" are activated
 */
class AnalyticsManager {
  constructor({ config, devMode }) {
    const analyticsConfiguration = devMode
      ? config.filter((c) => c.runInDev)
      : config
    this.devMode = devMode
    this._services = analyticsConfiguration.map(createAnalyticsInterface)
  }

  init({ userId }) {
    this.runForEachService((service) => {
      service.init({ userId })
      service.identifyUser(userId)
    })

    //@ts-ignore
    const { utm_email } = userService.getUserAttributes()
    if (utm_email) {
      this.setUserEmail(utm_email, 'utm')
    }
  }

  setUserEmail(email, source) {
    this.runForEachService((service) => service.setEmail(email))

    const eventProperties = {
      email,
      ...(source && { source }),
    }

    this.track(EVENT_NAMES.EMAIL_CAPTURED, eventProperties)
    LocalStorage.setUserEmail(email)
  }

  setUserName(name, source) {
    this.runForEachService((service) => service.setUserName(name))

    const eventProperties = {
      name,
      ...(source && { source }),
    }

    this.track(EVENT_NAMES.NAME_CAPTURED, eventProperties)
    LocalStorage.setUserName(name)
  }

  setUserProperties(userProperties) {
    this.runForEachService((service) =>
      service.setUserProperties(userProperties)
    )
  }

  track(eventName, eventProperties = {}, blackList = []) {
    try {
      if (this.devMode) {
        console.log(`[Event] ${eventName} - ${JSON.stringify(eventProperties)}`)
      }

      this.runForEachService((service) => {
        const serviceEventProperties = {
          ...eventProperties,
        }

        const eventIdPropertyName =
          service.name === ANALYTICS_SERVICES_NAMES.KLAVIYO
            ? '$event_id'
            : 'eventID'
        serviceEventProperties[eventIdPropertyName] = uuid()

        service.track(eventName, serviceEventProperties)
      }, blackList)
    } catch (error) {
      console.error(error)
    }
  }

  trackScreenView(screenName, props = {}) {
    if (isBrowser()) {
      const screenViewProps = {
        'Screen Name': screenName,
        Path: window.location.pathname,
        ...props,
      }
      this.track(EVENT_NAMES.SCREEN_VIEW, screenViewProps)

      window.Grin?.push(['view'])
    }
  }

  trackProductViewContent(product, activeVariant, collectionHandle) {
    this.runForEachService((service) => {
      service.trackViewContent?.({
        id: product.id.split('Product/')[1],
        name: product.title,
        collection: collectionHandle,
        value: activeVariant.price.amount,
        currency: activeVariant.price.currencyCode,
      })
    })
  }

  trackAddToCart(product, activeVariant, collectionHandle, origin) {
    const isSet = isVariantSKUASet(activeVariant.sku)
    this.track(EVENT_NAMES.ADD_TO_CART_CLICKED, {
      product: product.title,
      variant: activeVariant.title,
      sku: activeVariant.sku,
      size: collectionHandle,
      isSet,
      origin,
    })

    this.runForEachService((service) => {
      service.trackAddToCart?.({
        id: product.id.split('Product/')[1],
        name: product.title,
        collection: collectionHandle,
        variant: activeVariant,
        value: activeVariant.price.amount,
        currency: activeVariant.price.currencyCode,
      })
    })
  }

  runForEachService(func, blackList = []) {
    let servicesToRunOn = this._services

    if (blackList.length !== 0) {
      servicesToRunOn = servicesToRunOn.filter((service) => {
        return !blackList.includes(service.name)
      })
    }

    servicesToRunOn.forEach(func)
  }

  getService(serviceName) {
    if (!this._isValidService(serviceName)) {
      console.error('Service not found', serviceName)
      return null
    }

    return this._services.find((service) => service.name === serviceName)
  }

  _isValidService(serviceName) {
    return Object.values(ANALYTICS_SERVICES_NAMES).includes(serviceName)
  }

  isInDevMode() {
    return this.devMode
  }
}

export default new AnalyticsManager({
  config: analyticsConfig,
  devMode: !isProduction,
})
