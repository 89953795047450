import React, { useEffect, useMemo, useState } from 'react'
import { buildClient } from 'shopify-buy'
import { Context } from './Context'
import { LocalStorage, LocalStorageKeys } from './utils'
import { checkLineItemsAvailability } from '@lib/shopify/storefront-data-hooks/src/utils/types/isCart'
import retry from 'async-retry'

interface CommerceProviderProps {
  children: JSX.Element
  storefrontAccessToken: string
  domain: string
}

export const SHOPIFY_API_VERSION: string = '2023-04'

export const CommerceProvider: React.FC<CommerceProviderProps> = ({
  storefrontAccessToken,
  domain,
  children,
}) => {
  if (domain == null || storefrontAccessToken == null) {
    throw new Error(
      'Unable to build shopify-buy client object. Please make sure that your access token and domain are correct.'
    )
  }

  const initialCart = LocalStorage.getInitialCart()

  const [cart, setCart] = useState(initialCart)
  const [cartLoading, setCartLoading] = useState(false)
  const [cartDiscountLoading, setCartDiscountLoading] = useState(false)

  const isCustomDomain = domain.includes('.')

  const client = buildClient(
    {
      storefrontAccessToken,
      domain: isCustomDomain ? domain : `${domain}.myshopify.com`,
      apiVersion: SHOPIFY_API_VERSION,
    },
    async (url, options) => {
      return retry(async () => fetch(url, options as RequestInit), {
        retries: 3,
      })
    }
  )

  useEffect(() => {
    async function getNewCart() {
      try {
        setCart(await client.checkout.create())
      } catch (e) {
        console.error('Failed on getNewCart', e)
      }
    }

    async function refreshExistingCart(cartId: string) {
      try {
        const refreshedCart = await client.checkout.fetch(cartId)

        if (refreshedCart == null) {
          return getNewCart()
        }

        const cartHasBeenPurchased = Boolean(refreshedCart.completedAt)
        const allItemsInCartAreAvailable =
          checkLineItemsAvailability(refreshedCart)

        if (cartHasBeenPurchased || !allItemsInCartAreAvailable) {
          getNewCart()
        } else {
          setCart(refreshedCart)
        }
      } catch (error) {
        console.error('Failed on refreshExistingCart', error)
      }
    }

    if (cart == null) {
      getNewCart()
    } else {
      refreshExistingCart(String(cart.id))
    }
  }, [])

  useEffect(() => {
    LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(cart))
  }, [cart])

  const value = useMemo(
    () => ({
      client,
      cart,
      setCart,
      domain,
      cartLoading,
      setCartLoading,
      cartDiscountLoading,
      setCartDiscountLoading,
      storefrontAccessToken,
    }),
    [
      client,
      cart,
      setCart,
      domain,
      cartLoading,
      setCartLoading,
      cartDiscountLoading,
      setCartDiscountLoading,
      storefrontAccessToken,
    ]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}
