import React, { useEffect } from 'react'
import * as S from './AnnouncementBar.style'
import { isBrowser } from '@utils/runtimeUtils'
import StyledLink from '@components/common/StyledLink/StyledLink'
import analyticsManager from 'services/analytics/analyticsManager'
import EVENT_NAMES from 'consts/eventNames'
import { useHomepageEmailCaptureButton } from 'hooks/useHomepageCTAButton'

export interface AnnouncementProps {
  text: string
  useGeneralTitle?: boolean
}

export interface AnnouncementBarProps {
  stickyBarEnabled: boolean
  backgroundColor?: string
  fontColor?: string
  title?: string
  announcements: AnnouncementProps[]
}

export const isAnnouncementBarExpended = () => {
  if (isBrowser()) {
    const nav = document.querySelector('.announcement-bar')
    return nav?.classList.contains('expanded')
  } else {
    return true
  }
}

export const AnnouncementBar = ({
  stickyBarEnabled,
  backgroundColor,
  fontColor,
  title,
  announcements,
}: AnnouncementBarProps) => {
  const { redirectToEmailCapture, emailCaptureLink } =
    useHomepageEmailCaptureButton()

  const containerRef = React.useRef<HTMLDivElement>(null)
  const titleRef = React.useRef<HTMLDivElement>(null)

  const onClickBar = () => {
    analyticsManager.track(EVENT_NAMES.ANNOUNCEMENT_BAR_CLICKED)
  }

  useEffect(() => {
    const NAVIGATION_BAR_MIN_THRESHOLD = 110
    const NAVIGATION_BAR__MAX_THRESHOLD = 600
    let lastScrollY = window.scrollY

    if (!stickyBarEnabled) {
      containerRef?.current?.classList.add('fixed-position')
    } else {
      // solve double rendering issue (SSR server and later client side)
      containerRef?.current?.classList.remove('fixed-position')
    }

    const handleScroll = () => {
      if (
        lastScrollY >= NAVIGATION_BAR_MIN_THRESHOLD &&
        lastScrollY < window.scrollY - 50
      ) {
        containerRef?.current?.classList.remove('expanded')
        containerRef?.current?.classList.add('collapsed')
        lastScrollY = window.scrollY
      } else if (
        lastScrollY >= window.scrollY + NAVIGATION_BAR__MAX_THRESHOLD ||
        lastScrollY < NAVIGATION_BAR_MIN_THRESHOLD ||
        window.scrollY < NAVIGATION_BAR_MIN_THRESHOLD
      ) {
        containerRef?.current?.classList.remove('collapsed')
        containerRef?.current?.classList.add('expanded')
        lastScrollY = window.scrollY
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [stickyBarEnabled])

  return (
    <StyledLink
      href={
        redirectToEmailCapture
          ? emailCaptureLink(`/products/collections`)
          : `/products/collections`
      }
      onClick={onClickBar}
    >
      <S.Container
        className='announcement-bar'
        ref={containerRef}
        $backgroundColor={backgroundColor}
        $fontColor={fontColor}
        $hasTitle={!!title}
      >
        <S.Title ref={titleRef}>{title}</S.Title>
        <S.AnnouncementBarContainer>
          {announcements && announcements.length > 1 ? (
            announcements.map((announcement, index) => (
              <S.AnimatedWrapper
                key={announcement.text}
                $index={index}
                $length={announcements.length}
              >
                <p>
                  <b>{announcement.text}</b>
                </p>
              </S.AnimatedWrapper>
            ))
          ) : (
            <p>{announcements[0].text}</p>
          )}
        </S.AnnouncementBarContainer>
      </S.Container>
    </StyledLink>
  )
}
