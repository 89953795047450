import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as S from './NavbarContainer.styles'
import Spacer from '@components/ui/Spacer'
import { isCollectionsPage, isGiftingPage } from '@utils/runtimeUtils'
import {
  AnnouncementBar,
  AnnouncementBarProps,
} from '@components/Announcement/AnnouncementBar/AnnouncementBar'
import { useLargeScreen } from '@config/Theme/theme'
import { IHandle } from '@components/CollectionsTab/CollectionsTab'

type navbarProps = {
  collections: IHandle[]
}

interface NavbarContainerProps {
  announcement: AnnouncementBarProps
  Navbar: React.FC<navbarProps>
  navbarProps?: navbarProps
}

export function NavbarContainer({
  announcement,
  Navbar,
  navbarProps,
}: Readonly<NavbarContainerProps>) {
  const router = useRouter()
  const [rendered, setRendered] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const isCollectionPage = isCollectionsPage(router)
  const isGiftingPageFlag = isGiftingPage(router)
  const [navbarHeight, setNavbarHeight] = useState(0)

  const isDesktop = useLargeScreen()
  const NAVBAR_HEIGHT = isDesktop ? 56 : 44
  const EXTENDED_NAVBAR_HEIGHT = NAVBAR_HEIGHT + 54

  useEffect(() => {
    setRendered(true)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (isCollectionPage || isGiftingPageFlag) {
        setShowDropdown(false)
        return
      }
      const navBarHeight = announcement ? EXTENDED_NAVBAR_HEIGHT : NAVBAR_HEIGHT
      setShowDropdown(window.scrollY >= navBarHeight)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    updateNavbarHeight()
  }, [announcement, isDesktop])

  const updateNavbarHeight = () => {
    const navBarHeight = announcement ? EXTENDED_NAVBAR_HEIGHT : NAVBAR_HEIGHT
    setNavbarHeight(navBarHeight)
  }

  return (
    <>
      <S.Container $showDropdown={showDropdown} $stickyBarEnabled={rendered}>
        {announcement && rendered && (
          <AnnouncementBar stickyBarEnabled={rendered} {...announcement} />
        )}
        <Navbar {...navbarProps} />
      </S.Container>
      {rendered && <Spacer size={navbarHeight} />}
    </>
  )
}
