import { DISABLED_VARIANT, EXPERIMENTS_CONFIG } from './config'
import analyticsManager from '../analytics/analyticsManager'
import { ANALYTICS_SERVICES_NAMES } from '../analytics/analyticsConfig'
import { ExperimentName, UserExperiments } from './types'
import {
  forceQueryParamVariations,
  getForceVariations,
} from '../Features/forceVariations.amplitude'
import EVENT_NAMES from '../../consts/eventNames'
import { LocalStorage } from '@lib/shopify/storefront-data-hooks/src/utils'

const EXPERIMENTS_STORAGE_KEY = 'experiments'

export class ExperimentManager {
  exposedExperiments = new Set<{}>()

  getAllUserExperiments(): UserExperiments {
    const experiments = LocalStorage.get(EXPERIMENTS_STORAGE_KEY)
    return experiments ? JSON.parse(experiments) : {}
  }

  getUserVariant(experimentName: ExperimentName): string | undefined {
    const variant = this.getAllUserExperiments()[experimentName]
    if (variant && !this.exposedExperiments.has(experimentName)) {
      this.exposedExperiments.add(experimentName)
      analyticsManager
        .getService(ANALYTICS_SERVICES_NAMES.AMPLITUDE)
        .track(EVENT_NAMES.EXPERIMENT_EXPOSED, {
          [experimentName]: variant,
        })
    }
    return variant
  }

  saveAllUserExperiments(userExperiments: UserExperiments) {
    LocalStorage.set(EXPERIMENTS_STORAGE_KEY, JSON.stringify(userExperiments))
  }

  assignNewUserExperiments() {
    const userExperiments = this.getAllUserExperiments()
    Object.entries(EXPERIMENTS_CONFIG).forEach(([experimentName, config]) => {
      if (!userExperiments[experimentName]) {
        const variantChance = Math.random()
        let accumVariantsChance = 0
        userExperiments[experimentName] = config.variants.find((v) => {
          accumVariantsChance += v.distribution
          return accumVariantsChance >= variantChance
        }).name
        analyticsManager
          .getService(ANALYTICS_SERVICES_NAMES.AMPLITUDE)
          .track(EVENT_NAMES.EXPERIMENT_ASSIGNED, {
            [experimentName]: userExperiments[experimentName],
          })
      }
    })

    this.saveAllUserExperiments(userExperiments)
    return userExperiments
  }

  saveForcedVariants() {
    const forcedVariations = forceQueryParamVariations()
    if (forcedVariations) {
      analyticsManager
        .getService(ANALYTICS_SERVICES_NAMES.AMPLITUDE)
        .track(EVENT_NAMES.EXPERIMENTS_FORCED, {
          ...forcedVariations,
        })
      analyticsManager.setUserProperties({
        ...forcedVariations,
      })
    }
  }

  init() {
    const userExperiments = this.assignNewUserExperiments()
    analyticsManager.setUserProperties(userExperiments)
    analyticsManager
      .getService(ANALYTICS_SERVICES_NAMES.SENTRY)
      ?.setAllExperimentData(userExperiments)

    this.saveForcedVariants()
  }

  getExperimentVariant(experimentName: ExperimentName): string | undefined {
    const forcedVariation = getForceVariations(experimentName)
    if (forcedVariation) {
      return forcedVariation
    }

    return this.getUserVariant(experimentName)
  }

  isExperimentEnabled(experimentName: ExperimentName) {
    const experimentVariant = this.getExperimentVariant(experimentName)
    return experimentVariant && experimentVariant !== DISABLED_VARIANT
  }
}

export const staticExperimentManager = new ExperimentManager()
