import React from 'react'
import type ShopifyBuy from 'shopify-buy'

interface ContextShape {
  client: ShopifyBuy | null
  cart: ShopifyBuy.Checkout | null
  setCart: React.Dispatch<React.SetStateAction<ShopifyBuy.Checkout | null>>
  domain: string
  storefrontAccessToken: string
  cartLoading: Boolean
  setCartLoading: React.Dispatch<React.SetStateAction<Boolean>>
  cartDiscountLoading: Boolean
  setCartDiscountLoading: React.Dispatch<React.SetStateAction<Boolean>>
}

export const Context = React.createContext<ContextShape>({
  client: null,
  cart: null,
  domain: '',
  storefrontAccessToken: '',
  setCart: () => {
    throw Error('You forgot to wrap this in a Provider object')
  },

  cartLoading: false,
  setCartLoading: () => {
    throw Error('You forgot to wrap this in a Provider object')
  },

  cartDiscountLoading: false,
  setCartDiscountLoading: () => {
    throw Error('You forgot to wrap this in a Provider object')
  },
})
