export enum Collections {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  HUGE = 'huge',
  HOLIDAYS = 'holidays',
  SPECIAL_OFFERS = 'special-offers',
  VALENTINES = 'valentines',
  HOME_PAGE_RECOMMENDATIONS = 'home-page-recommendations',
  TREE_FORM = 'tree-form',
}

const hiddenInCollectionsTab: Collections[] = [
  Collections.SPECIAL_OFFERS,
  Collections.HOLIDAYS,
  Collections.VALENTINES,
  Collections.HOME_PAGE_RECOMMENDATIONS,
  Collections.TREE_FORM,
]

export const TagsToCollections: Record<Collections | string, string> = {
  set: 'collections',
  [Collections.SMALL]: 'small',
  [Collections.MEDIUM]: 'medium',
  [Collections.LARGE]: 'large',
  [Collections.HUGE]: 'huge',
}

export const getFeaturedCollections = (): Record<
  string,
  string | Collections
> => ({
  COLLECTIONS: 'collections',
  ...Collections,
})

export const getCollectionsInTabs = (): Record<
  string,
  string | Collections
> => {
  return Object.entries(getFeaturedCollections()).reduce(
    (collectionsInTabs: Record<string, string | Collections>, [key, value]) => {
      if (hiddenInCollectionsTab.includes(value as Collections)) {
        return collectionsInTabs
      }

      collectionsInTabs[key] = value

      return collectionsInTabs
    },
    {}
  )
}
