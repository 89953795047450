import Script from 'next/script'

const Impact = () => (
  <Script id='impact' strategy='afterInteractive'>
    {`
    (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.async=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A2951962-c164-46b5-86aa-761bb748ae001.js','script','ire',document,window);
      `}
  </Script>
)

export default Impact
