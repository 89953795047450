import React from 'react'

const Close = ({ onClick, height = '24px', width = '24px', ...props }) => {
  return (
    <div onClick={onClick} {...props} style={{ height, width }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height={height}
        width={width}
        viewBox='0 0 24 24'
        fill='currentColor'
      >
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
      </svg>
    </div>
  )
}

export default Close
